import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "body" }
const _hoisted_2 = { class: "form-block w-form" }
const _hoisted_3 = { class: "login-form-inputs-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Notification = _resolveComponent("Notification")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[13] || (_cache[13] = _createStaticVNode("<div class=\"logo-medium\"></div><div class=\"big-header\"><span class=\"brand-color\">CHIRPJS</span></div><div class=\"welcome-text\">snippets &amp; endless possibilities</div><div class=\"vertical-spacer\"></div>", 4)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("form", {
        class: "form",
        onSubmit: _cache[4] || (_cache[4] = 
          (e) => {
            e.preventDefault();
            _ctx.register();
          }
        )
      }, [
        _createElementVNode("div", _hoisted_3, [
          _cache[5] || (_cache[5] = _createElementVNode("label", {
            for: "username",
            class: "input-label"
          }, "Registration E-Mail", -1)),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
            class: "input-text w-input",
            autofocus: "true",
            maxlength: "256",
            type: "e-mail"
          }, null, 512), [
            [_vModelText, _ctx.email]
          ]),
          _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
          _cache[7] || (_cache[7] = _createElementVNode("label", {
            for: "username",
            class: "input-label"
          }, "Password", -1)),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
            class: "input-text w-input",
            autofocus: "true",
            maxlength: "256",
            type: "password"
          }, null, 512), [
            [_vModelText, _ctx.password]
          ]),
          _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
          _cache[9] || (_cache[9] = _createElementVNode("label", {
            for: "username",
            class: "input-label"
          }, "Re-Type Password", -1)),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.rePassword) = $event)),
            class: "input-text w-input",
            autofocus: "true",
            maxlength: "256",
            type: "password"
          }, null, 512), [
            [_vModelText, _ctx.rePassword]
          ])
        ]),
        _cache[10] || (_cache[10] = _createElementVNode("input", {
          class: "btn w-button",
          type: "submit",
          value: "Register"
        }, null, -1)),
        _cache[11] || (_cache[11] = _createElementVNode("div", { class: "text-center" }, "Or", -1)),
        _createElementVNode("div", {
          class: "btn google w-button",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.push('/')))
        }, "Login")
      ], 32)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["user-setup-div", { open: _ctx.showUserSetupLoading }])
    }, _cache[12] || (_cache[12] = [
      _createStaticVNode("<div class=\"logo-large with-text\"></div><div class=\"vertical-spacer\"></div><div class=\"welcome-text\">snippets &amp; endless possibilities</div><div class=\"vertical-spacer\"></div><div class=\"setup-animation\"></div><div class=\"account-setup-text\">Setting up your journey</div>", 6)
    ]), 2),
    (_ctx.showNotification)
      ? (_openBlock(), _createBlock(_component_Notification, {
          key: 0,
          options: _ctx.notificationOptions
        }, null, 8, ["options"]))
      : _createCommentVNode("", true)
  ]))
}