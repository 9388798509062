<template>
  <div class="form-input-wrapper">
    <label class="script-tag-label" :class="{ dev: env == 'dev', prod: env == 'prod' }" v-if="lang == 'html'">
      HTML
      <span class="script-copy-action-btn" @click="showUrl">URL</span>
      <span class="script-copy-action-btn" @click="showIframe">iFrame Element</span>
    </label>
    <label class="script-tag-label" :class="{ dev: env == 'dev', prod: env == 'prod' }" v-if="lang == 'css'">
      CSS
      <span class="script-copy-action-btn" @click="showUrl">URL</span>
      <span class="script-copy-action-btn" @click="showLinkTag">HTML Link Tag</span>
    </label>
    <label class="script-tag-label" :class="{ dev: env == 'dev', prod: env == 'prod' }" v-if="lang == 'js'">
      JavaScript
      <span class="script-copy-action-btn" @click="showUrl">URL</span>
      <span class="script-copy-action-btn" @click="showScriptTag">HTML Script Tag</span>
    </label>
    <div class="script-tag-input-wrapper">
      <input class="form-input w-input" type="text" v-model="renderedUrl">
      <div class="script-generating-loading" v-if="showLoading"></div>
      <div class="gradient-wrapper">
        <div class="gradient script-tag"></div>
        <div class="btn script-copy" v-if="!isCopied" @click="copyInputText(renderedUrl)">Copy</div>
        <div class="btn script-copy done" v-else>Copied</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { db, storage } from "@/firebase-app";
import router from "@/router";
import { doc, getDoc, setDoc } from "firebase/firestore";
import {
  getDownloadURL, ref as storageRef,
  StorageReference,
  uploadString
} from "firebase/storage";
import { defineComponent } from "vue";
import { mapActions, mapMutations, mapState } from "vuex";

export default defineComponent({
  name: "ScriptTagOutput",
  data() {
    return {
      showLoading: false,
      isCopied: false,
      fileName: "",
      codeUrl: "",
      renderedUrl: "",
    };
  },
  props: {
    env: String,
    lang: String,
  },
  mounted() {
    // craete file name based on lang prop
    switch (this.lang) {
      case 'html':
        this.fileName = 'index.html'
        break
      case 'css':
        this.fileName = 'style.css'
        break
      case 'js':
        this.fileName = 'script.js'
        break
    }
    console.log(`Target File: ${this.env}/${this.fileName}`);
    this.getCodeUrl();
  },
  computed: {
    ...mapState("modLogin", ["user"]),
    ...mapState("modProjects", [
      "selectedProject",
    ]),
    ...mapState("modVersioning", ["selectedVersion"]),
  },

  methods: {
    ...mapActions("modChirp", ["deleteChirp"]),
    ...mapMutations(["setCreateProdScriptFlag"]),
    showUrl() {
      this.renderedUrl = this.codeUrl
    },
    showIframe() {
      this.renderedUrl = `<iframe src='${this.codeUrl}' width='100%' height='100%' style='border: none'"><\/iframe>`
    },
    showLinkTag() {
      this.renderedUrl = `<link rel='stylesheet' href='${this.codeUrl}'>`
    },
    showScriptTag() {
      this.renderedUrl = `<script src='${this.codeUrl}'"><\/script>`
    },
    async getCodeUrl() {
      this.showLoading = true
      // check if there is a published version then create the link to it
      let envProperty = ""
      switch (this.env) {
        case "dev":
          envProperty = "devVersion"
          break
        case "prod":
          envProperty = "prodVersion"
          break
      }
      if (this.selectedProject[envProperty] !== undefined) {
        console.log("Target Env: ", this.selectedProject[envProperty])
        const versionDocRef = doc(
          db,
          `users/${this.user.uid}/projects/${this.selectedProject.id}/versions/${this.selectedProject[envProperty]}`
        );
        const versionDoc = await getDoc(versionDocRef)
        if (versionDoc.exists()) {
          await getDownloadURL(
            storageRef(
              storage,
              `scripts/${this.user.uid}/${this.selectedProject.id}/${this.env}/${this.fileName}`
            )
          ).then((url) => {
            this.showLoading = false
            console.log(`${this.env} - ${this.fileName} URL: `, url)
            this.codeUrl = url
            this.renderedUrl = url
          }).catch((error) => {
            this.showLoading = false
            console.log(`${this.env} - ${this.fileName} URL Error: `, error)
          });
        } else {
          console.log(`verion ${this.selectedProject[envProperty]} does not exists`)
          this.showLoading = false
        }
      } else {
        this.showLoading = false
      }
    },
    
    copyInputText(text: string) {
      navigator.clipboard.writeText(text)
        .then(() => {
          this.isCopied = true
          setTimeout(() => {
            this.isCopied = false
          }, 1000)
        })
        .catch(err => {
          console.error('Failed to copy text: ', err);
        });
    },
  },
});
</script>
