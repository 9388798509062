import { db, storage } from "../firebase-app";
import { Module } from "vuex";
import {
  collection,
  deleteDoc,
  doc,
  DocumentData,
  documentId,
  getCountFromServer,
  onSnapshot,
  query,
  where,
  collectionGroup,
  setDoc,
  getDocs,
  getDoc,
  QueryFieldFilterConstraint,
  Query,
} from "firebase/firestore";

const modChirps: Module<any, any> = {
  namespaced: true,
  state: {
    selectedChirp: null as any,
    chirps: [{}],
    newChirpDialog: false,
    chirpSearchSnap: null as any,
    publicChirps: [] as null[],
  },
  mutations: {
    setChirpSearchSnap(state, value) {
      state.chirpSearchSnap = value;
    },
    setNewChirpDialog(state, bool) {
      state.newChirpDialog = bool;
    },
    setSelectedChirp(state, chirp) {
      state.selectedChirp = chirp;
    },
    setChirps(state, chirps) {
      state.chirps = chirps;
    },
    setPublicChirps(state, publicChirps) {
      state.publicChirps = publicChirps;
    },
  },
  getters: {
    getUserChirps(state): any[] | null {
      console.log("getting user chirps: ", state.userProjects);
      return state.chirps;
    },
  },
  actions: {
    async getPublicChirps({ commit }) {
      const chirpsCollectionGroup = collectionGroup(db, "chirps");
      const q = query(chirpsCollectionGroup, where("public", "==", true));
      const snap = await getDocs(q);
      const publicChirps = [] as any[];
      snap.forEach((doc) => {
        const publicChirp = {
          id: doc.id,
          ...doc.data(),
        };
        publicChirps.push(publicChirp);
      });
      commit("setPublicChirps", publicChirps);
    },
    async searchForChirps(
      { rootGetters, commit, state },
      { searchTerm, isPublic }
    ) {
      if (state.chirpSearchSnap !== null) {
        console.log("Snapshot:", state.chirpSearchSnap);
        state.chirpSearchSnap();
      }

      let chirpCollection = null;
      const queryFiltersArray: QueryFieldFilterConstraint[] = [];
      let searchQuery = null as unknown as Query;

      if (isPublic) {
        chirpCollection = collectionGroup(db, "chirps");
        queryFiltersArray.push(where("public", "==", true));
      } else {
        const user = rootGetters["modLogin/currentUser"];
        chirpCollection = collection(db, `users/${user.uid}/chirps`);
      }

      if (searchTerm != null) {
        const searchWords = searchTerm.split(" ");
        queryFiltersArray.push(
          where("searchWords", "array-contains-any", searchWords)
        );
      }

      searchQuery = query(chirpCollection, ...queryFiltersArray);
      const searchSnap = onSnapshot(searchQuery, (snapshot) => {
        const results = [] as any[];
        snapshot.forEach((doc) => {
          const chirp = doc.data();
          chirp.id = doc.id;
          results.push(chirp);
        });
        console.log("searching for chirps", results);
        commit("setChirps", results);
      });
      commit("setChirpSearchSnap", searchSnap);
    },
    async getChirp({ rootGetters }, chirpId): Promise<any> {
      const user = rootGetters["modLogin/currentUser"];
      const chirpRef = doc(db, `users/${user.uid}/chirps`, chirpId);
      let chirp = null;
      try {
        const chirpSnap = await getDoc(chirpRef);
        if (chirpSnap.exists()) {
          chirp = chirpSnap.data();
          console.log("chirp data: ", chirpSnap.data());
        } else {
          console.log("No such chirp, sorry!");
        }
      } catch (error) {
        console.log("Error getting chirp: ", error);
      }
      return chirp;
    },
    async fetchUserChirps({ commit, state }, userId) {
      if (state.chirpSearchSnap) {
        state.chirpSearchSnap();
      }
      const chirpsRef = collection(db, `users/${userId}/chirps`);
      const searchSnap = onSnapshot(chirpsRef, (querySnapshot) => {
        const chirps: DocumentData[] = [];
        querySnapshot.forEach((doc) => {
          const chirp = doc.data();
          chirp.id = doc.id;
          chirps.push(chirp);
        });
        commit("setChirps", chirps);
        console.log("Chirps: ", chirps);
      });
      commit("setChirpSearchSnap", searchSnap);
    },
    async chirpExist({ rootGetters }, chirpName) {
      const user = rootGetters["modLogin/currentUser"];
      const q = query(
        collection(db, `users/${user.uid}/chirps`),
        where(documentId(), "==", chirpName)
      );
      const snap = await getCountFromServer(q);
      console.log("CHECKING FOR EXISTING PROJECT");
      if (snap.data().count > 0) {
        console.log("CHIRP WITH THE SAME NAME EXISTS");
        return true;
      } else {
        console.log("CHIRP WITH THE SAME NAME DOES NOT EXIST");
        return false;
      }
    },
    async createNewChirp(
      { dispatch, rootGetters },
      { newChirpName, newChirpDesc }
    ): Promise<object> {
      if (newChirpName.length < 5) {
        alert("Chirp name is too short (less than 5 letters)");
        return {
          success: false,
          id: null,
        };
      }
      let result = {
        success: false,
        id: "",
      };
      const user = rootGetters["modLogin/currentUser"];
      const chirpName = newChirpName.replace(/ /g, " ").toLowerCase();
      const searchWords = chirpName.split(" ");
      const chirpExist = await dispatch("chirpExist", chirpName);
      if (!chirpExist) {
        const chirpId = Date.now().toString();
        const chirp = {
          id: chirpId,
          code: `console.log("Just keep on chirpping bud!")`,
          name: chirpName,
          searchWords: searchWords,
          desc: newChirpDesc,
          createdDate: Date.now(),
          updatedDate: Date.now(),
          uid: user.uid,
          author: user.displayName,
          public: false,
        };
        await setDoc(doc(db, `users/${user.uid}/chirps`, chirpId), chirp)
          .then((docRef: any) => {
            console.log(`Chirp created with ID: ${chirpId}`, docRef);
            result = {
              success: true,
              id: chirpId,
            };
          })
          .catch((error) => {
            console.error("Error creating chirp:", error);
            result = {
              success: false,
              id: "",
            };
          });
      }
      return result;
    },
    async deleteChirp({ rootGetters }, chirpId): Promise<boolean> {
      let deleteSuccess = false;
      const user = rootGetters["modLogin/currentUser"];
      await deleteDoc(doc(db, `users/${user.uid}/chirps`, chirpId))
        .then(() => {
          deleteSuccess = true;
        })
        .catch((error) => {
          console.log("Error deleting chirp: ", error);
          deleteSuccess = false;
        });
      return deleteSuccess;
    },
  },
};
export default modChirps;
