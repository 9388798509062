<template>
  <div class="left-panel-list project" v-if="selectedProject">
    <div class="version-tab-head">
      <div class="selected-version-details-wrapper">
        <div class="selected-version-details-col" v-if="selectedVersion">
          <div class="selected-version-name">
            {{ selectedVersion.number }}
          </div>
          <div class="chirp-result-author">
            {{ new Date(selectedVersion.created).toLocaleDateString() }} -
            {{ new Date(selectedVersion.created).toLocaleTimeString() }}
          </div>
          <div class="chirp-result-details">
            {{ selectedVersion.comments }}
          </div>
        </div>
        <div class="selected-version-details-col" v-else>
          <div class="selected-version-name">Current Code</div>
          <div class="chirp-result-author">Right Now</div>
          <div class="chirp-result-details">...</div>
        </div>
        
        <div class="selected-version-actions-col">
          <div class="btn version-action" v-if="!selectedVersion && !showVersionInput"
            @click="selectedVersion = null; showVersionInput = true;">
            Save
          </div>
          <div class="btn version-action green" @click="restoreVersion()" v-if="selectedVersion">
            Restore
          </div>
          <div class="btn version-action blue" @click="setShowScriptTags(true)" v-if="selectedVersion">
            Scipt Tags
          </div>
          <div class="btn version-action red" v-if="selectedVersion" @click="areDeletingVersion = true">
            Delete
          </div>
        </div>
      </div>
      <div class="selected-version-details-wrapper deleting" v-if="areDeletingVersion && selectedVersion">
        <div class="selected-version-details-col">
          <div class="version-item-text">
            Are you sure you want to remove version number
            <strong>{{ selectedVersion.number }}</strong>
          </div>
        </div>
        <div class="selected-version-actions-col deleteing">
          <div class="btn version-action red" @click="handleDeleteVersion">
            Yes
          </div>
          <div class="btn version-action gray" @click="areDeletingVersion = false">
            Cancel
          </div>
        </div>
        <div class="app-loading-div" v-if="areDeletingVersionLoading">
          <div class="app-loading-animation"></div>
        </div>
      </div>
      <div class="selected-version-details-wrapper" v-if="showVersionInput">
        <div class="selected-version-details-col">
          <div class="selected-version-name" v-if="selectedVersion">
            {{ selectedVersion.number }}
          </div>
          <div class="chirp-result-author" v-if="selectedVersion">
            {{ selectedVersion.created }}
          </div>
          <div class="w-form" @submit="(e) => {
            e.preventDefault();
            handleSaveVersion(versionComments);
          }
            ">
            <form>
              <textarea placeholder="Comment" class="version-comment-input w-input"
                v-model="versionComments"></textarea>
            </form>
          </div>
        </div>
        <div class="selected-version-actions-col">
          <div class="btn version-action" @click="handleSaveVersion(versionComments)">
            Save
          </div>
          <div class="btn version-action gray" @click="showVersionInput = false">
            Cancel
          </div>
        </div>
        <div class="app-loading-div" v-if="saveVersionLoading">
          <div class="app-loading-animation"></div>
        </div>
      </div>
    </div>
    <div class="versions-list">
      
      <div class="version-item current"
        :class="{ selected: !selectedVersion }"
        @click="setSelectedVersion(null)">
        <div class="version-item-text title">Current Work</div>
        <div class="version-item-text sub">Right Now</div>

      </div>
      <div class="version-item" :class="{ selected: selectedVersion && selectedVersion.number == version.number, }"
        v-for="version in versions" :key="version.number" @click="
        if (selectedVersion && selectedVersion.number == version.number) {
        } else {
          setSelectedVersion(version)
        }

          showVersionInput = false;

          ">
        <div class="version-item-text title">
          {{ version.number }}
        </div>
        <div class="version-item-text sub">
          {{ new Date(version.created).toLocaleDateString() }} -
          {{ new Date(version.created).toLocaleTimeString() }}
        </div>
        <div class="version-item-text">
          {{ version.comments }}
        </div>
        <div class="icon" v-if="version.id == selectedProject.prodVersion || version.id == selectedProject.devVersion"
          :class="{
            'prod-version': version.id == selectedProject.prodVersion,
            'dev-version': version.id == selectedProject.devVersion,
            'dev-prod-version': version.id == selectedProject.prodVersion && version.id == selectedProject.devVersion
          }">rocket_launch</div>

      </div>
      <div class="versions-list-load-wrapper">
        <div class="btn version-action load-more hidden">Load More</div>
      </div>
    </div>
  </div>
  <Notification v-if="showNotification" :options="notificationOptions" />
</template>

<script lang="ts">
import router from "@/router";
import { defineComponent, shallowRef } from "vue";
import { mapActions, mapMutations, mapState } from "vuex";
import { db } from "@/firebase-app";
import { doc, getDoc, setDoc } from "firebase/firestore";
import Notification from "@/components/Notification.vue";


export default defineComponent({
  name: "VersioningComp",
  emits: ['tabChange'],
  components: {
    Notification,
  },
  data() {
    return {
      showVersionInput: false,
      saveVersionLoading: false,
      versionComments: "",
      areDeletingVersion: false,
      areDeletingVersionLoading: false,
      showNotification: false,
      notificationOptions: null as any,
    }
  },

  computed: {
    ...mapState("modLogin", ["user"]),
    ...mapState("modVersioning", [
      "selectedVersion",
      "versions", "jsCode", "htmlCode", "cssCode"
    ]),
    ...mapState("modProjects", ["selectedProject"])
  },
  methods: {
    ...mapMutations(["setAppIsLoading", "setProjectMenuTab"]),
    ...mapMutations("modProjects", ["setShowScriptTags"]),
    ...mapMutations("modVersioning", [
      "setSelectedVersion", "setJsCode", "setHtmlCode", "setCssCode"
    ]),
    ...mapActions("modVersioning", [
      "createNewVersion",
      "updateVersionComments",
      "deleteVersion",
    ]),
    async handleSaveVersion(comments: string) {
      console.log("CODE: ", this.selectedProject.jsCode)
      console.log("CODE: ", this.selectedProject.cssCode)
      console.log("CODE: ", this.selectedProject.htmlCode)
      this.saveVersionLoading = true;
      await this.createNewVersion({
        code: {
          jsCode: this.selectedProject.jsCode,
          htmlCode: this.selectedProject.htmlCode,
          cssCode: this.selectedProject.cssCode,
        },
        comments: comments,
        projectId: this.selectedProject.id,
        created: new Date().getTime()
      });
      this.versionComments = "";
      this.showVersionInput = false;
      this.saveVersionLoading = false;
    },
    async restoreVersion() {
      const warning = {
        question: true, // question | confirm
        yesText: "Confirm & Restore",
        noText: "Discard",
        title: `Restoring From Version ${this.selectedVersion.number}`,
        text: "This will replace your current work. Any unsaved changes will be lost.",
        yesFunction: async () => {
          this.showNotification = false;
          this.setAppIsLoading(true);
          // set project selected version
          const projectDoc = doc(
            db,
            `users/${this.user.uid}/projects/${this.selectedProject.id}`
          );
          await setDoc(projectDoc, {
            jsCode: this.selectedVersion.jsCode,
            htmlCode: this.selectedVersion.htmlCode,
            cssCode: this.selectedVersion.cssCode,
          }, { merge: true })
          this.setJsCode(this.selectedVersion.jsCode)
          this.setHtmlCode(this.selectedVersion.htmlCode)
          this.setCssCode(this.selectedVersion.cssCode)
          this.setSelectedVersion(this.selectedVersion)
          this.setProjectMenuTab('AI')
          this.notificationOptions = null as any;
          this.setAppIsLoading(false);
        },
        noFunction: () => {
          this.showNotification = false;
          this.notificationOptions = null as any;
        },
        isError: Boolean,
      };
      this.notificationOptions = warning;
      this.showNotification = true;

    },
    async handleDeleteVersion() {
      this.areDeletingVersionLoading = true;
      await this.deleteVersion({
        projectId: this.selectedProject.id,
        versionId: this.selectedVersion.id,
      });
      this.selectedVersion = null;
      this.areDeletingVersion = false;
      this.areDeletingVersionLoading = false;
    },
  },
});
</script>
