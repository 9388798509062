<template>
  <div class="body">
    <div class="big-header brand-color">ChirpJS</div>
    <div class="welcome-text">snippets & endless possibilities</div>
    <div class="vertical-spacer"></div>
    <div class="form-block w-form" @submit="(e) => {
      e.preventDefault();
      confirmNameChange();
    }">
      <form class="form">
        <div class="login-form-inputs-wrapper">
          <label for="username" class="input-label">Choose a name</label
          ><input
            v-model="newName"
            @input="isNameAvailable()"
            class="input-text w-input"
            autofocus="true"
            maxlength="256"
            type="text"
          />
          <div
            class="input-feedback-wrapper"
            v-show="
              newName &&
              newName.length > 5 &&
              nameIsUnique &&
              !isLoading &&
              !checkingName
            "
          >
            <label class="input-feedback">Name available</label
            ><label class="input-feedback-icon">check</label>
          </div>
          <div
            class="input-feedback-wrapper"
            v-show="
              newName &&
              newName.length > 5 &&
              !nameIsUnique &&
              !isLoading &&
              !checkingName &&
              newName != userData.name
            "
          >
            <label class="input-feedback red">Name is not available</label>
            <label class="input-feedback-icon red">close</label>
          </div>
          <div class="input-feedback-wrapper" v-show="checkingName">
            <div class="name-check-loading"></div>
          </div>
        </div>
        <div
          class="btn w-button"
          v-show="
            newName &&
            !isLoading &&
            nameIsUnique &&
            newName.length > 5 &&
            !checkingName
          "
          @click="confirmNameChange"
        >
          Next
        </div>
        <div
          class="btn w-button disabled"
          v-show="
            newName &&
            !isLoading &&
            (!nameIsUnique || newName.length <= 5 || checkingName) &&
            newName != userData.name
          "
        >
          Next
        </div>
        <div
          class="btn w-button"
          v-show="newName == userData.name"
          @click="confirmNameChange"
        >
          I Like This Name
        </div>
        <div class="loading-wrapper" v-show="isLoading">
          <div class="loading-name"></div>
        </div>
      </form>
    </div>
    <div class="user-setup-div" :class="{ open: showUserSetupLoading }">
      <div class="logo-large with-text"></div>
      <div class="vertical-spacer"></div>
      <div class="welcome-text">snippets & endless possibilities</div>
      <div class="vertical-spacer"></div>
      <div class="setup-animation"></div>
      <div class="account-setup-text">Setting up your journey</div>
    </div>
    <Notification v-if="showNotification" :options="notificationOptions" />
  </div>
</template>

<script lang="ts">
import router from "@/router";
import { defineComponent } from "vue";
import { getFunctions, httpsCallable } from "firebase/functions";
import Notification from "@/components/Notification.vue";

import { mapActions, mapMutations, mapState } from "vuex";
import store from "@/store";
export default defineComponent({
  name: "NameView",
  components: {
    Notification,
  },
  data() {
    return {
      newName: "",
      newNameOnServer: "",
      nameIsUnique: false,
      isLoading: false,
      checkingName: false,
      notificationOptions: null as any,
    };
  },
  setup() {
    //
  },
  async mounted() {
    if (this.user) await store.dispatch("modLogin/getUserData", this.user.uid);
  },

  computed: {
    ...mapState("modLogin", ["user", "userData"]),
    ...mapState(["showNotification"]),
    ...mapState("modLogin", ["user", "userData", "showUserSetupLoading"]),
  },
  watch: {
    userData(newVal, oldVal) {
      this.newName = newVal.name;
      console.log("newVal", newVal);
    },
  },
  methods: {
    ...mapActions("modLogin", ["signinWithGoogle"]),
    ...mapMutations("modLogin", ["setShowUserSetupLoading"]),
    ...mapMutations(["setShowNotification"]),
    async isNameAvailable() {
      if (this.newName.length <= 5) return;
      this.checkingName = true;
      const functions = getFunctions();
      const checkName = httpsCallable(functions, "isUniqueName");
      await checkName({ newName: this.newName }).then(async (result: any) => {
        this.nameIsUnique = result.data?.nameIsUnique;
        this.newNameOnServer = result.data?.newName;
        this.checkingName = false;
      });
    },
    async applyNewName() {
      this.setShowUserSetupLoading(true);
      const functions = getFunctions();
      const changeName = httpsCallable(functions, "changeName");
      // Define a default error for name change failure
      const errorNameChange = {
        isError: true,
        question: false,
        yesText: "ok",
        noText: "",
        title: "Can't change your name",
        text: "Looks like we can't change your name at this moment.",
        yesFunction: null,
        noFunction: null,
      };
      if (
        (this.newName.length > 5 && this.nameIsUnique) ||
        this.newName == this.userData.name
      )
        try {
          await changeName({ name: this.newName, uid: this.user.uid })
            .then(async (result: any) => {
              console.log(result);
              if (result.data == true) {
                await this.user.reload();
                this.setShowUserSetupLoading(false);
                router.push("/workspace");
              } else {
                //
                errorNameChange.text = "Error from server";
                this.notificationOptions = errorNameChange;
                this.setShowNotification(true);
              }
            })
            .catch((error) => {
              console.log(error);
              router.push("/");
            });
        } catch (error) {
          this.setShowUserSetupLoading(false);
          errorNameChange.text = "Error before attempt to change";
          this.notificationOptions = errorNameChange;
          this.setShowNotification(true);
        }
    },
    confirmNameChange() {
      let assigningName = "";
      if (this.newNameOnServer != "") {
        assigningName = this.newNameOnServer;
      } else {
        assigningName = this.userData.name;
      }
      this.notificationOptions = {}; // reset notification
      this.notificationOptions.question = true;
      this.notificationOptions.yesText = "Confirm";
      this.notificationOptions.noText = "Discard";
      this.notificationOptions.title = "Setting Up Your Name";
      this.notificationOptions.text = `Your username will be ${assigningName}`;
      this.notificationOptions.yesFunction = () => {
        this.applyNewName();
        this.setShowNotification(false);
      };
      this.setShowNotification(true);
    },
  },
});
</script>
