<template>
  <div class="left-panel-list project">
    <div class="preview-tab-title-wrapper">
      <div class="doc-status-wrapper">
        <div class="doc-stateus-text">Preview</div>

      </div>
      <div class="comp-header-icon-wrapper">
        <div class="btn project-control doc-save icon" @click="openRouteInNewTab(`/preview/${selectedProject.id}`)">
          open_in_new</div>
        <div class="btn project-control doc-save icon" @click="updatePreview()">refresh</div>
      </div>

    </div>

    <iframe style="background-color: white; width: 100%; height: 100%; color: black;" v-if="showPreview"
      :srcdoc="renderedHtmlCode">
    </iframe>


  </div>
</template>

<script lang="ts">
import router from "@/router";
import { defineComponent } from "vue";
import { mapActions, mapMutations, mapState } from "vuex";

export default defineComponent({
  name: "AppLoading",
  data() {
    return {
      content: "",
      cdnScripts: "",
      renderedHtmlCode: ``,
    }
  },


  mounted() {
    this.selectedProject.cdnList.forEach((cdn: string) => {
      this.cdnScripts += `<script src="${cdn}"><\/script>`
    });
    if (this.selectedVersion !== null) {

      this.renderedHtmlCode = "<head><style>" + this.selectedVersion.cssCode + "<\/style><\/head>"
      this.renderedHtmlCode = this.renderedHtmlCode +
        "<body>" +
        this.selectedVersion.htmlCode + this.cdnScripts +
        "<script>" +
        this.selectedVersion.jsCode +
        "<\/script><\/body>"
    } else {
      this.renderedHtmlCode = "<head><style>" + this.selectedProject.cssCode + "<\/style><\/head>"
      this.renderedHtmlCode = this.renderedHtmlCode +
        "<body>" +
        this.selectedProject.htmlCode + this.cdnScripts +
        "<script>" +
        this.selectedProject.jsCode +
        "<\/script><\/body>"
    }
  },
  watch: {
    selectedProject(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.renderedHtmlCode = "<head><style>" + this.selectedProject.cssCode + "<\/style><\/head>"

        this.renderedHtmlCode = this.renderedHtmlCode +
          "<body>" +
          this.selectedProject.htmlCode + this.cdnScripts +
          "<script>" +
          this.selectedProject.jsCode +
          "<\/script><\/body>"
      }
    },
    selectedVersion(newVal, oldVal) {
      if (newVal !== null) {
        this.renderedHtmlCode = "<head><style>" + this.selectedVersion.cssCode + "<\/style><\/head>"

        this.renderedHtmlCode = this.renderedHtmlCode +
          "<body>" +
          this.selectedVersion.htmlCode + this.cdnScripts +
          "<script>" +
          this.selectedVersion.jsCode +
          "<\/script><\/body>"
      } else {
        this.renderedHtmlCode = "<head><style>" + this.selectedProject.cssCode + "<\/style><\/head>"

        this.renderedHtmlCode = this.renderedHtmlCode +
          "<body>" +
          this.selectedProject.htmlCode + this.cdnScripts +
          "<script>" +
          this.selectedProject.jsCode +
          "<\/script><\/body>"
      }
    },
  },

  computed: {
    ...mapState("modProjects", ["selectedProject"]),
    ...mapState("modVersioning", ["selectedVersion"]),
    ...mapState(["showNotification", "showPreview"]),
  },
  methods: {
    ...mapMutations(["refreshPreview", "updatePreview"]),
    openRouteInNewTab(route: string) {
      window.open(router.resolve(route).href, '_blank')
    }
  }
});
</script>
