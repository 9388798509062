<template>
  <div class="tags-div" v-if="selectedVersion !== null">
    <div class="script-tags-box">
      <div class="script-tags-box-title"><strong>Scripts Tags</strong> (Version: {{ selectedVersion.number }})</div>
      <div class="script-tags-toggle">
        <div class="script-tag-toggle-btn" :class="{ 'selected-dev': selectedEnv == 'dev' }"
          @click="selectedEnv = 'dev'">
          Development Scripts</div>
        <div class="script-tag-toggle-btn" :class="{ 'selected': selectedEnv == 'prod' }" @click="selectedEnv = 'prod'">
          Production Scripts</div>
      </div>
      <div class="scripts-form-block dev w-form" :class="{ open: selectedEnv == 'dev' }">
        <div class="script-tag-actions-wrapper">
          <div class="btn move-to-dev" @click="publishToDev" v-if="selectedProject.devVersion !== selectedVersion.id">
            <div class="normal-icon">done</div>
            <div class="btn-text">Publish to Development</div>
          </div>
          <div class="btn move-to-dev disabled" v-else>
            <div class="normal-icon">done</div>
            <div class="btn-text">Already Published</div>
          </div>
        </div>
        <form class="scripts-tags-form" v-if="showDevOutput">
          <!--Dev HTML Script-->
          <ScriptTagOutput :env="'dev'" :lang="'html'" />
          <!--Dev CSS Script-->
          <ScriptTagOutput :env="'dev'" :lang="'css'" />
          <!--Dev JavaScript Script-->
          <ScriptTagOutput :env="'dev'" :lang="'js'" />
        </form>
        <div v-else style="width: 500px;"></div>
      </div>
      <div class="scripts-form-block prod w-form" :class="{ open: selectedEnv == 'prod' }">
        <div class="script-tag-actions-wrapper">
          <div class="btn move-to-prod" v-if="selectedProject.prodVersion !== selectedVersion.id" @click="moveToProd">
            <div class="normal-icon">done</div>
            <div class="btn-text">Move to Production</div>
          </div>
          <div class="btn move-to-prod disabled" v-else>
            <div class="normal-icon">done</div>
            <div class="btn-text">Already in Production</div>
          </div>
        </div>
        <form class="scripts-tags-form" v-if="showProdOutput">
          <!--Prod HTML Script-->
          <ScriptTagOutput :env="'prod'" :lang="'html'" />
          <!--Prod CSS Script-->
          <ScriptTagOutput :env="'prod'" :lang="'css'" />
          <!--Prod Javascript Script-->
          <ScriptTagOutput :env="'prod'" :lang="'js'" />
        </form>
        <div v-else style="width: 500px;"></div>
      </div>
      <div class="script-tags-box-btn-wrapper">
        <div class="notification-btn" @click="setShowScriptTags(false)">Hide</div>
      </div>
      <div class="code-upload-loading-wrapper" v-if="showLoading">
        <div class="code-upload-loading"></div>
        <div class="text-center">Publishing</div>
      </div>
    </div>

  </div>
  <div class="code-not-saved-wrapper" v-if="selectedVersion == null">
    <div class="welcome-text">Code is not versioned. Create a new version or select one from the versions library
    </div>
    <div class="notification-btn blue">Create a new Version</div>
    <br>
    <div class="notification-btn disabled" @click="setShowScriptTags(false)">Cancel</div>
  </div>
  <Notification v-if="showNotification" :options="notificationOptions" />


</template>

<script lang="ts">
import router from "@/router";
import { defineComponent, version } from "vue";
import { mapActions, mapMutations, mapState } from "vuex";
import ScriptTagOutput from "./ScriptTagOutput.vue";
import { storage } from "@/firebase-app";
import {
  getDownloadURL, ref as storageRef,
  StorageReference,
  uploadString
} from "firebase/storage";
import Notification from "@/components/Notification.vue";


export default defineComponent({
  name: "ChirpCard",
  components: {
    ScriptTagOutput,
    Notification,
  },
  data() {
    return {
      selectedEnv: 'dev',
      notificationOptions: null as any,
      showNotification: false,
      showLoading: false,
      cdnScripts: "",
      showDevOutput: false,
      showProdOutput: false,
    };
  },
  mounted() {
    if(this.selectedProject.devVersion){
      this.showDevOutput = true
    }
    if(this.selectedProject.prodVersion){
      this.showProdOutput = true
    }
  },
  computed: {
    ...mapState("modLogin", ["user"]),
    ...mapState("modProjects", [
      "selectedProject",
    ]),
    ...mapState("modVersioning", ["selectedVersion"]),
  },
  watch: {
    selectedProject(newVal, oldVal) {
      if(newVal.devVersion){
        this.showDevOutput = true
      }
      
      if(newVal.prodVersion){
        this.showProdOutput = true
      }
      
    }
  },
  methods: {
    ...mapActions("modChirp", ["deleteChirp"]),
    ...mapActions("modVersioning", ["markVersionProd", "markVersion", "selectedVersion"]),
    ...mapMutations("modProjects", ["setShowScriptTags"]),
    ...mapMutations(["setCreateProdScriptFlag"]),
    async publishToDev() {
      this.showLoading = true
      await this.uploadCode('dev', 'index.html', this.selectedVersion)
      await this.uploadCode('dev', 'style.css', this.selectedVersion)
      await this.uploadCode('dev', 'script.js', this.selectedVersion)
      await this.markVersion({
        projectId: this.selectedProject.id,
        versionId: this.selectedVersion.id,
        env: 'dev'
      });
      this.showLoading = false
    },
    async moveToProd() {
      this.notificationOptions = {
        question: true, // question | confirm
        yesText: 'Move To Production',
        noText: 'Cancel',
        title: `Moving To Production`,
        text: 'This will overwrite the production code and mark this version as the production version',
        yesFunction: async () => {
          this.showLoading = true
          this.showNotification = false;
          await this.uploadCode('prod', 'index.html', this.selectedVersion)
          await this.uploadCode('prod', 'style.css', this.selectedVersion)
          await this.uploadCode('prod', 'script.js', this.selectedVersion)
          await this.markVersion({
            projectId: this.selectedProject.id,
            versionId: this.selectedVersion.id,
            env: 'prod'
          });

          this.notificationOptions = null as any;
          this.showLoading = false
        },
        noFunction: () => {
          this.showNotification = false;
          this.notificationOptions = null as any;

        },
        isError: false,
      };
      this.showNotification = true;
    },
    async uploadCode(env: string, fileName: string, versionDoc: any) {
      this.showLoading = true
      this.cdnScripts = ""
      this.selectedProject.cdnList.forEach((cdn: string) => {
        this.cdnScripts += `<script src="${cdn}"><\/script>`
      });
      const codeStorageRef = storageRef(
        storage,
        `scripts/${this.user.uid}/${this.selectedProject.id}/${env}/${fileName}`
      );
      let code = "";
      switch (fileName) {
        case 'index.html':
          code = `<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-UA-Compatible" content="ie=edge">
    <title>${this.selectedProject.id}<\/title>
    <style>${versionDoc.cssCode}<\/style>
  <\/head>
  <body>
    ${versionDoc.htmlCode}
    ${this.cdnScripts}
    <script type="text/javascript">${versionDoc.jsCode}<\/script>
  <\/body>
<\/html>`
          break
        case 'style.css': code = versionDoc.cssCode; break
        case 'script.js': code = versionDoc.jsCode; break
      }
      const metadata = {
        contentType: 'text/html', // Set the correct content type
      };
      await uploadString(codeStorageRef, code, 'raw', metadata).then((snapshot) => {
        console.log("Uploaded a raw string!", snapshot);
      });
      this.showLoading = false
    },

  },
});
</script>
