<template>
  <div class="left-panel-list project ai">
    <div class="ai-tab-header-div">
      <div class="ai-header-text details">
        <div class="ai-comp-title-row">
          <div class="ai-comp-title-col">
            <div class="chirp-result-title" @click="console.log(messages)">AI Assistance</div>
            <div class="chirp-result-author">Local Ollama</div>
          </div>
          <div class="ai-comp-title-col">
            <div class="btn project-control full-width" v-if="!showSettings"
              @click="showSettings = true; newSystemPrompt = systemPrompt">
              Settings
            </div>
          </div>
        </div>
        <div class="chirp-result-details">Running Locally on
          <a href="https://ollama.com/" target="_blank" class="google-ai-link">Ollama</a>.
          Selected LLM Model is <strong>{{ selectedLlmModel }}</strong>


        </div>
      </div>
      <div class="ai-update-form w-form" v-if="showSettings">
        <div style="height: 10px"></div>
        <form class="chirp-search-form-wrapper" style="grid-row-gap: 0;">
          <div class="dropdown-label">Ollama Host Url:</div>
          <div class="chirp-search-input-wrapper">
            <input class="chirp-search-input w-input" placeholder="Ollama Host Url" type="text" v-model="newHostUrl" />
          </div>
        </form>
        <div class="dropdown-with-label">
          <div class="dropdown-label"><span class="inline-icon" @click="getChatList();">refresh</span>Available Models:
            (<a href="https://ollama.com/" target="_blank" class="google-ai-link">Download Models</a>)
          </div>
          <div class="dropdown-input full-width">
            <div class="dropdown-items-wrapper" v-if="
              showModelsList" v-click-outside="() => {
                showModelsList = false
              }
                ">

              <div class="dropdown-item" v-for="(model, index) in modelsList" :key="index"
                @click="chosenModel = model.name; showModelsList = false">{{ model.name }}
              </div>
              <div class="dropdown-item" v-if="modelsList.length == 0" @click="showModelsList = !showModelsList">No
                Models</div>
            </div>
            <div class="dropdown-item" @click="showModelsList = !showModelsList">{{ chosenModel }}</div>
            <div class="dropdown-button" @click="showModelsList = !showModelsList">arrow_drop_down</div>
          </div>
        </div>
        <div style="height: 10px"></div>
        <form class="chirp-search-form-wrapper" style="grid-row-gap: 0;">
          <div class="dropdown-label">System Prompt:</div>
          <div class="chirp-search-input-wrapper">

            <textarea class="ai-chat-input w-input" maxlength="5000" v-model="newSystemPrompt" @keydown.enter.prevent="(e) => {
              if (e.shiftKey) {
                // add a newline character to the textarea content
                newSystemPrompt += '\n';
              }
            }
              "></textarea>
          </div>

        </form>
        <div style="height: 10px"></div>
        <form class="chirp-search-form-wrapper" style="grid-row-gap: 0;">
          <div class="dropdown-label">Temperature:</div>
          <div class="chirp-search-input-wrapper">
            <input class="chirp-search-input w-input" placeholder="Temperature" type="text" v-model="ModelTemp" />
          </div>
        </form>
        <div style="height: 10px"></div>
        <div class="ai-apli-update-btns-wrapper">
          <div class="btn project-control full-width" @click="updateAiSettings">
            Update
          </div>
          <div class="btn project-control full-width cancel" @click="showSettings = false">
            Cancel
          </div>
        </div>
        <div class="api-updating-loading" v-if="false"></div>
        <br>
      </div>
    </div>

    <div class="ai-tab-header-div" v-if="!showSettings">
      
      <div class="ai-actions-wrapper">
        <div class="dropdown-input">
          <div class="dropdown-items-wrapper" v-if="
            showHistory" v-click-outside="() => {
              showHistory = false
            }
              ">

            
            <div class="dropdown-item" v-for="(chat, index) in chatList" :key="index" @click="selectChat(chat)"
              :title="chat.title">{{ chat.title }}
            </div>
            <div class="dropdown-item" v-if="chatList.length == 0">No Chat History</div>
          </div>
          <div class="dropdown-item" v-if="!selectedChat" @click="showHistory = !showHistory">Unsaved Chat</div>
          <div class="dropdown-item" v-else @click="showHistory = !showHistory" :title="selectedChat.title">{{ selectedChat.title }}</div>
          <div class="dropdown-button" @click="showHistory = !showHistory">arrow_drop_down</div>
        </div>
        <div class="ai-chat-actions-wrapper">
          <div class="btn project-control icon full-width red" title="Delete This Chat" v-if="selectedChat"
            @click="isDeleting = true">
            delete</div>
          <div class="btn project-control icon full-width disabled" title="No Selected Chat to Delete" v-else>
            delete
          </div>
          <div class="btn project-control icon full-width green" title="Start a New Chat" @click="startNewChat">add
          </div>
        </div>
      </div>
      <div class="ai-actions-wrapper" v-if="isDeleting">
        <div class="btn project-control full-width red" @click="deleteChat()">Delete Chat</div>
        <div class="btn project-control full-width cancel" @click="isDeleting = false">Cancel</div>
      </div>
    </div>
    <div class="ai-tab-chat-wrapper" v-if="!showSettings">
      <div class="ai-ask-buttons">
        <div class="ai-chat-window" ref="scrollContainer">
          <div class="chirp-search-result-group" v-for="(message, index) in messages" :key="index">

            <div class="ai-chat-block-wrapper" v-if="message.role == 'user'">
              <div class="ai-chat-block" v-html="message.content.replace('@@css', `<span style='color: skyblue'>@@css</span>`)
                .replace('@@html', `<span style='color: skyblue'>@@html</span>`)
                .replace('@@js', `<span style='color: skyblue'>@@js</span>`)">

              </div>
            </div>

            <div class="ai-chat-block-wrapper ai" v-if="message.role == 'assistant'">
              <Markdown class="ai-chat-block ai" :content="message.content" />
              <div class="btn ai-chat-action" @click="branchChat(index+1)"><span class="icon-span">account_tree</span>Branch</div>
              <div class="ai-response-fader"></div>
            </div>
          </div>
          <div class="ai-chat-block-wrapper ai" v-if="aiThinking">
            <Markdown class="ai-chat-block ai" :content="aiResponse" />
            <div class="ai-loading"></div>
          </div>

          <div class="btn project-control full-width ai-regen"
            v-if="messages.length > 0 && messages[messages.length - 1].role == 'assistant'"
            @click="regenerateLastResponse">
            Regenerate Last Response</div>
          <div class="btn-ai-chat-go-to-bottom" v-if="messages.length > 2" @click="scrollToBottom">
            vertical_align_bottom
          </div>
        </div>


      </div>
      <div class="btn project-control full-width red" v-if="aiThinking" @click="stopResponse = true">
        Stop Response
      </div>
      <div class="ai-input-form w-form w-form" @submit="(e) => {
        e.preventDefault();
        messageAi(aiPrompt);
        aiPrompt = '';
        scrollToBottom();
      }
        ">
        <form class="chirp-search-form-wrapper">
          <div class="ai-chat-input-wrapper">
            <textarea class="ai-chat-input w-input" placeholder="Chat with AI" v-model="aiPrompt"
              @keydown.enter.prevent="(e) => {
                if (e.shiftKey) {
                  // add a newline character to the textarea content
                  aiPrompt += '\n';
                } else {
                  messageAi(aiPrompt);
                  aiPrompt = '';
                  scrollToBottom();
                }
              }
                "></textarea>
            <div class="ai-chat-icon-wrapper" @click="
              messageAi(aiPrompt);
            aiPrompt = '';
            scrollToBottom();
            ">
              <div class="normal-icon">send</div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="ai-api-key-setup" v-if="modelsList.length == 0">
      <div class="chirp-page-title">Local Ollama</div>
      <div class="vertical-spacer"></div>
      <div class="api-key-instruction">
        Make sure you have <a href="https://ollama.com/" target="_blank" class="google-ai-link">Ollama</a> installed and
        running
      </div>
      <div>
        <br>
        <form class="chirp-search-form-wrapper" style="grid-row-gap: 0;" @submit="(e) => {
          e.preventDefault()
          defaultHostUrl = newHostUrl
          initAi()
        }">
          <div class="dropdown-label">Ollama Host Url:</div>
          <div class="chirp-search-input-wrapper">
            <input class="chirp-search-input w-input" placeholder="Ollama Host Url" type="text" v-model="newHostUrl" />
            <button class="btn save" type="submit"> Refresh </button>
          </div>
        </form>

      </div>

    </div>
  </div>
</template>

<script lang="ts">
import { db } from "@/firebase-app";
import router from "@/router";
import { addDoc, collection, CollectionReference, deleteDoc, doc, DocumentData, DocumentReference, getDocs, onSnapshot, orderBy, query, setDoc, updateDoc } from "firebase/firestore";
import { defineComponent, stop } from "vue";
import { mapActions, mapMutations, mapState } from "vuex";
import axios from 'axios';
import Markdown from "./Markdown.vue";
type Message = {
  role: "assistant" | "user" | "system";
  content: string;
}
export default defineComponent({
  name: "AiChat",
  components: {
    Markdown,
  },
  data() {
    return {
      stopResponse: false,
      ModelTemp: '0.8' as string,
      defaultHostUrl: "http://localhost:11434",
      newHostUrl: "http://localhost:11434",
      chosenModel: "",
      systemPrompt: "You are a helpful AI agent.",
      newSystemPrompt: "",
      modelsList: [] as any,
      showSettings: false,
      isSaving: false,
      isDeleting: false,
      showHistory: false,
      showModelsList: false,
      aiThinking: false,
      aiPrompt: "",
      messages: [] as Message[] | any,
      aiResponse: "",
      selectedLlmModel: '',
      selectedChat: null as any,
      chatList: [] as any[],
      firstChatSnapshot: true,
      chatSnapshot: null as any,
    };
  },
  props: {
    oprions: null as any,
    relation: {
      type: String,
      default: "project"
    },
  },
  async mounted() {
    await this.initAi();
    this.getChatList();
  },
  computed: {
    ...mapState("modChirp", ["selectedChirp"]),
    ...mapState("modLogin", ["userData", "user"]),
    ...mapState("modProjects", ["selectedProjectId", "selectedProject"]),
  },
  methods: {
    ...mapMutations("modChirp", ["setSelectedChirp"]),

    async initAi() {

      this.messages.push({
        role: "system",
        content: this.systemPrompt,
      })
      try {
        const getModelsList = await axios.get(`${this.defaultHostUrl}/api/tags`)
        this.modelsList = getModelsList.data.models
        this.selectedLlmModel = this.modelsList[0].name
        this.chosenModel = this.selectedLlmModel
        console.log(this.modelsList)
        console.log(this.messages)
        this.newSystemPrompt = this.systemPrompt
        // update ollama host for this project
        const projectDoc = doc(db, `users/${this.user.uid}/projects/${this.selectedProject.id}`)
        await updateDoc(projectDoc, {
          ["ollamaHost"]: this.defaultHostUrl
        })
      } catch (err) {
        console.log(err);

      }
    },

    async askOllama(prompt: string) {
      this.aiResponse = ""
      console.log("asking: ", prompt);


      const response = await fetch(`${this.defaultHostUrl}/api/generate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          model: this.selectedLlmModel, // specify your model here
          prompt: prompt,
          stream: true
        })
      });

      // Check for an OK response
      if (!response || !response.body) { return }
      if (!response.ok) throw new Error('Failed to stream response');

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      // eslint-disable-next-line no-constant-condition
      while (true) {
        this.aiThinking = true
        const { done, value } = await reader.read();
        if (done) break;

        // Decode chunk and append to resultText
        const chunk = decoder.decode(value, { stream: true });
        try {
          const chunkJson = JSON.parse(chunk)
          this.aiResponse += chunkJson.response || "";
        } catch (error) {

        }

        // Process chunk in real-time (e.g., display or log)
        //console.log(chunk);  // Each chunk of data as it arrives
      }
      this.aiThinking = false
      return this.aiResponse;  // Final complete response

    },

    scrollToBottom() {
      setTimeout(() => {
        const container: HTMLElement = this.$refs
          .scrollContainer as HTMLElement;
        container.scrollTop = container.scrollHeight;
      }, 200);
    },

    async messageAi(question: string) {
      const completeQuestion = question.replace(/@@css/g, this.selectedProject.cssCode).replace(/@@js/g, this.selectedProject.jsCode).replace(/@@html/g, this.selectedProject.htmlCode)

      console.log("Question after replacing @@: ", completeQuestion)
      this.messages.push({ role: "user", content: question });
      this.messages.push(await this.chat(this.messages));
      this.aiResponse = ""

      this.saveChat()

      console.log(this.messages)
    },

    async chat(messages: Message[]) {
      if (!messages || messages.length == 0) {
        return
      }
      this.aiResponse = "...";
      this.stopResponse = false;
      this.aiThinking = true;
      // code aware system prompt
      const codeAwarePrompt = `
        ${this.systemPrompt}.
        1. read the question carfully.
        2. Every time the user mention @@css, it refers to ${this.selectedProject.cssCode}.
        3. Every time the user mention @@js, it refers to ${this.selectedProject.jsCode}.
        4. Every time the user mention @@html, it refers to ${this.selectedProject.htmlCode}.
      `

      this.messages[0].content = this.systemPrompt
      const body = {
        model: this.selectedLlmModel,
        messages: messages,
        options: {
          temperature: parseFloat(this.ModelTemp)
        }
      }

      const response = await fetch(`${this.defaultHostUrl}/api/chat`, {
        method: "POST",
        body: JSON.stringify(body)
      })
      // Check for an OK response
      if (!response || !response.body) { return }
      if (!response.ok) throw new Error('Failed to stream response');

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      this.aiResponse = "";
      // eslint-disable-next-line no-constant-condition
      while (true && !this.stopResponse) {
        const { done, value } = await reader.read();
        if (done) break;

        // Decode chunk and append to resultText
        const chunk = decoder.decode(value, { stream: true });

        try {
          const chunkJson = JSON.parse(chunk)
          this.aiResponse += chunkJson.message.content;
        } catch (error) {

        }

      }
      this.stopResponse = true
      this.aiThinking = false
      return { role: "assistant", content: this.aiResponse };

    },

    updateAiSettings() {
      if (this.newSystemPrompt) {
        this.systemPrompt = this.newSystemPrompt
        this.messages[0].content = this.systemPrompt
      }
      if (this.chosenModel) {
        this.selectedLlmModel = this.chosenModel
        this.chosenModel = this.selectedLlmModel
      }
      this.defaultHostUrl = this.newHostUrl
      this.showSettings = false
    },

    async regenerateLastResponse() {
      this.messages.pop()
      this.messages.push(await this.chat(this.messages))
      this.saveChat()
    },

    async getChatList() {
      console.log(`getting chats for user: ${this.user.uid} - project: ${this.selectedProjectId}`)

      let chatCollection = null as unknown as CollectionReference;
      if (this.relation == "project") {
        chatCollection = collection(db, `users/${this.user.uid}/projects/${this.selectedProjectId}/aiChats`)
      } else {
        chatCollection = collection(db, `users/${this.user.uid}/chirps/${this.selectedChirp.id}/aiChats`)
      }
      const q = query(chatCollection, orderBy("created", "desc"))
      this.chatSnapshot = onSnapshot(q, (snapshot) => {
        const chatList: DocumentData[] = [];
        snapshot.forEach((doc) => {
          const chat = doc.data();
          chat.id = doc.id;
          chatList.push(chat);
          console.log(`Chats(${this.chatList.length})`, this.chatList)
          console.log(chat)
        });
        this.chatList = chatList;
        if (this.firstChatSnapshot == true) {
          this.selectRecentChat()
          this.firstChatSnapshot = false
        }
      });
    },

    selectChat(chat: any) {
      if (chat.model && this.modelsList.some((model: { name: any; }) => model.name === chat.model)) {
        this.chosenModel = chat.model
        this.selectedLlmModel = chat.model
      } else {
        console.log("Name does not exist in any index");
      }
      this.selectedChat = chat;
      this.messages = chat.history
      this.systemPrompt = chat.systemPrompt
      this.ModelTemp = chat.temp
    },

    selectRecentChat() {
      if (this.chatList.length > 0) {
        this.selectChat(this.chatList[0])
      } else {
        this.selectedChat = null
        this.messages = [] as Message[]
        this.messages.push({
          role: "system",
          content: this.systemPrompt,
        })
      }
    },

    async saveChat() {
      this.aiThinking = true;

      if (this.selectedChat && this.relation == 'project') { // update selected chat if one is selected
        console.log(`saving chat for the selected chat ${this.selectedChat.title}: `, this.messages);
        const chatDoc = doc(db, `users/${this.user.uid}/projects/${this.selectedProjectId}/aiChats`, this.selectedChat.id);
        await setDoc(chatDoc, {
          ["history"]: this.messages,
        }, { merge: true })
        this.aiThinking = false;
        return
      }
      if (this.selectedChat && this.relation == 'chirp') { // update selected chat if one is selected
        console.log(`saving chat for the selected chat ${this.selectedChat.title}: `, this.messages);
        const chatDoc = doc(db, `users/${this.user.uid}/chirps/${this.selectedChirp.id}/aiChats`, this.selectedChat.id);
        await setDoc(chatDoc, {
          ["history"]: this.messages,
        }, { merge: true })
        this.aiThinking = false;
        return
      }
      // create chat name
      const title = await this.createChatTitle()
      // project -> aiChat -> newChat
      let chatCollection = null as unknown as CollectionReference;
      if (this.relation == "project") {
        chatCollection = collection(db, `users/${this.user.uid}/projects/${this.selectedProjectId}/aiChats`)
      } else {
        chatCollection = collection(db, `users/${this.user.uid}/chirps/${this.selectedChirp.id}/aiChats`)
      }

      await addDoc(chatCollection, {
        history: this.messages,
        title: title,
        created: new Date().getTime(),
        model: this.chosenModel,
        systemPrompt: this.systemPrompt,
        temp: this.ModelTemp
      }).then((result) => {
        console.log('saved chat: ', result)
      }).catch((error) => {
        console.log("error saving chat: ", error)
      })
      this.selectRecentChat();
      this.aiThinking = false;
      console.log("Chat saved with title: ", title)
    },

    async branchChat(index: number){
      this.aiThinking = true;
      let chatCollection = null as unknown as CollectionReference;
      if (this.relation == "project") {
        chatCollection = collection(db, `users/${this.user.uid}/projects/${this.selectedProjectId}/aiChats`)
      } else {
        chatCollection = collection(db, `users/${this.user.uid}/chirps/${this.selectedChirp.id}/aiChats`)
      }

      await addDoc(chatCollection, {
        history: this.messages.slice(0, index),
        title: `Branched - ${this.selectedChat.title}`,
        created: new Date().getTime(),
        model: this.chosenModel,
        systemPrompt: this.systemPrompt,
        temp: this.ModelTemp
      }).then((result) => {
        console.log('saved chat: ', result)
      }).catch((error) => {
        console.log("error saving chat: ", error)
      })
      this.selectRecentChat();
      this.aiThinking = false;
    },
    startNewChat() {
      this.selectedChat = null
      this.messages = []
      this.messages.push({
        role: "system",
        content: this.systemPrompt,
      })
    },

    async createChatTitle() {
      this.aiThinking = true;
      console.log(this.messages)

      const titleQuestion = `The following dialog is a conversation between a user and an assistant.
      user: ${this.messages[1].content}
      assistant: ${this.messages[2].content}
      Give a short detailed title for this conversation. your answer should be the title only.
      `
      console.log(titleQuestion)
      const result = await this.askOllama(titleQuestion);
      console.log(result);

      this.aiThinking = false;
      console.log(result);
      return result;
    },

    async deleteChat() {
      this.isDeleting = true;
      console.log(`deleting the selected chat ${this.selectedChat.title}: `, this.messages);
      let chatDoc = null as unknown as DocumentReference
      if (this.selectedChat && this.relation == 'project') { // update selected chat if one is selected
        chatDoc = doc(db, `users/${this.user.uid}/projects/${this.selectedProjectId}/aiChats`, this.selectedChat.id);
      } else {
        chatDoc = doc(db, `users/${this.user.uid}/chirps/${this.selectedChirp.id}/aiChats`, this.selectedChat.id);
      }
      await deleteDoc(chatDoc);
      this.selectRecentChat();
      this.isDeleting = false;
    },

  },
});
</script>
