import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "editor-title"
}
const _hoisted_2 = {
  key: 0,
  style: {"color":"orange","margin-left":"5px"}
}
const _hoisted_3 = {
  key: 1,
  class: "editor-title"
}
const _hoisted_4 = {
  key: 0,
  style: {"color":"orange","margin-left":"5px"}
}
const _hoisted_5 = {
  key: 2,
  class: "editor-title"
}
const _hoisted_6 = {
  key: 0,
  style: {"color":"orange","margin-left":"5px"}
}
const _hoisted_7 = {
  key: 3,
  class: "code-editor-holder"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueMonacoEditor = _resolveComponent("VueMonacoEditor")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.language == 'javascript')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _cache[0] || (_cache[0] = _createTextVNode("Javascript ")),
          (_ctx.code != _ctx.selectedProject.jsCode && _ctx.code != '')
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, "(Not Saved)"))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.language == 'html')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _cache[1] || (_cache[1] = _createTextVNode("HTML ")),
          (_ctx.code != _ctx.selectedProject.htmlCode && _ctx.code != '')
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, "(Not Saved)"))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.language == 'css')
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _cache[2] || (_cache[2] = _createTextVNode("CSS ")),
          (_ctx.code != _ctx.selectedProject.cssCode && _ctx.code != '')
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, "(Not Saved)"))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.code != null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_component_VueMonacoEditor, {
            theme: _ctx.theme,
            options: _ctx.MONACO_EDITOR_OPTIONS,
            language: _ctx.language,
            onMount: _ctx.handleMount,
            onChange: _ctx.codeUpdated,
            value: _ctx.code
          }, null, 8, ["theme", "options", "language", "onMount", "onChange", "value"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}