<template>
  <div class="workspace-item-wrapper">
    <div class="item-icon active">code</div>
    <div class="item-detail-wrapper" @click="goToProject(project.name)">
      <div class="item-details title project">
        {{ project.name }}
      </div>
      <div class="item-details light project">By: {{ project.author }}</div>
      <div class="item-details light bold project">
        Updated: {{ new Date(project.updatedDate).toLocaleString() }}
      </div>
    </div>
    <div class="item-icon" @click="showSettings = true">settings</div>
    <div class="item-options" v-if="showSettings">
      <div class="item-option red" @click="deleteQuestion = true">Delete</div>
      <div class="item-option-close-btn" @click="showSettings = false">
        close
      </div>
    </div>
    <div class="item-options" v-if="showSettings && deleteQuestion">
      <div class="item-option" @click="deleteQuestion = false">Cancel</div>
      <div
        class="item-option red"
        @click="
          deleteProject(project.name);
          deleteQuestion = false;
          showSettings = false;
        "
      >
        Delete
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import router from "@/router";
import { defineComponent } from "vue";
import { mapActions } from "vuex";

export default defineComponent({
  name: "ProjectCard",
  data() {
    return {
      showSettings: false,
      deleteQuestion: false,
    };
  },
  props: {
    project: {} as any,
  },
  computed: {
    //
  },
  methods: {
    ...mapActions("modProjects", ["deleteProject"]),
    goToProject(projectPath: string) {
      router.push(`/project/${projectPath}`);
    },
  },
});
</script>
