import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuillEditor = _resolveComponent("QuillEditor")!

  return (_openBlock(), _createBlock(_component_QuillEditor, {
    class: "chirp-search-result-code-preview quill-editor",
    contentType: "html",
    theme: "snow",
    toolbar: _ctx.toolbarOptions,
    content: _ctx.content,
    "onUpdate:content": _ctx.contentUpdated
  }, null, 8, ["toolbar", "content", "onUpdate:content"]))
}