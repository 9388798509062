import { db } from "../firebase-app";
import { Module } from "vuex";
import {
  collection,
  deleteDoc,
  doc,
  DocumentData,
  getDoc,
  onSnapshot,
  query,
} from "firebase/firestore";
import store from ".";

const modProjects: Module<any, any> = {
  namespaced: true,
  state: {
    userProjects: [] as any[],
    selectedProject: null as any,
    projectSnapshot: null as any,
    selectedProjectId: String || null,
    showScriptTags: false,
    documentationInServer: "",
    documentationInClient: "",

    firstSnapshot: true,
  },
  mutations: {
    setProjectSnapshot(state, value) {
      state.projectSnapshot = value
    },
    setSelectedProject(state, value) {
      state.selectedProject = value
    },

    setShowScriptTags(state, value) {
      state.showScriptTags = value;
    },
    setSelectedProjectId(state, projectId) {
      state.selectedProjectId = projectId;
    },
    setUserProjects(state, projects) {
      state.userProjects = projects;
    },
    setFirstSnapshot(state, value) {
      state.firstSnapshot = value
    },
  },
  getters: {
    getUserProjects(state): any[] | null {
      console.log("getting user projects: ", state.userProjects);
      return state.userProjects;
    },
  },
  actions: {
    async fetchUserProjects({ state, commit }, userId) {
      const projectsRef = collection(db, `users/${userId}/projects`);
      onSnapshot(projectsRef, (querySnapshot) => {
        const projects: DocumentData[] = [];
        querySnapshot.forEach((doc) => {
          projects.push(doc.data());
        });
        commit("setUserProjects", projects);
      });
    },
    getProject({ rootGetters, commit, state }, projectId) {
      if (state.projectSnapShot) {
        state.projectSnapShot();
      }


      const user = rootGetters["modLogin/currentUser"];
      const projectRef = doc(db, `users/${user.uid}/projects`, projectId);
      commit("setProjectSnapshot", onSnapshot(projectRef, (snapshot) => {

        const projectData: any = {
          id: snapshot.id,
          ...snapshot.data(),
        }
        commit("setSelectedProject", projectData);
        if (state.firstSnapshot) {
          commit("setFirstSnapshot", false)
          console.log("project first snapshot");
        }
      }));

    },
    async deleteProject({ rootGetters }, projectId) {
      store.commit("setAppIsLoading", true);
      const user = rootGetters["modLogin/currentUser"];
      const projectRef = doc(db, `users/${user.uid}/projects`, projectId);
      await deleteDoc(projectRef);
      store.commit("setAppIsLoading", false);
    },
  },
};
export default modProjects;
