<template>
  <div class="app-loading-div">
    <div class="logo-large with-text"></div>
    <div class="app-loading-wrapper">
      <div class="app-loading-animation effect"></div>
      <div class="app-loading-text">Loading</div>
    </div>
  </div>
</template>

<script lang="ts">
import router from "@/router";
import { defineComponent } from "vue";
import { mapActions } from "vuex";

export default defineComponent({
  name: "AppLoading",
});
</script>
