import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "body" }
const _hoisted_2 = { class: "form" }
const _hoisted_3 = { class: "login-form-inputs-wrapper" }
const _hoisted_4 = { class: "input-feedback-wrapper" }
const _hoisted_5 = { class: "input-feedback-wrapper" }
const _hoisted_6 = { class: "input-feedback-wrapper" }
const _hoisted_7 = { class: "btn w-button disabled" }
const _hoisted_8 = { class: "loading-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Notification = _resolveComponent("Notification")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[11] || (_cache[11] = _createElementVNode("div", { class: "big-header brand-color" }, "ChirpJS", -1)),
    _cache[12] || (_cache[12] = _createElementVNode("div", { class: "welcome-text" }, "snippets & endless possibilities", -1)),
    _cache[13] || (_cache[13] = _createElementVNode("div", { class: "vertical-spacer" }, null, -1)),
    _createElementVNode("div", {
      class: "form-block w-form",
      onSubmit: _cache[4] || (_cache[4] = (e) => {
      e.preventDefault();
      _ctx.confirmNameChange();
    })
    }, [
      _createElementVNode("form", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[8] || (_cache[8] = _createElementVNode("label", {
            for: "username",
            class: "input-label"
          }, "Choose a name", -1)),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newName) = $event)),
            onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isNameAvailable())),
            class: "input-text w-input",
            autofocus: "true",
            maxlength: "256",
            type: "text"
          }, null, 544), [
            [_vModelText, _ctx.newName]
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_4, _cache[5] || (_cache[5] = [
            _createElementVNode("label", { class: "input-feedback" }, "Name available", -1),
            _createElementVNode("label", { class: "input-feedback-icon" }, "check", -1)
          ]), 512), [
            [_vShow, 
              _ctx.newName &&
              _ctx.newName.length > 5 &&
              _ctx.nameIsUnique &&
              !_ctx.isLoading &&
              !_ctx.checkingName
            ]
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_5, _cache[6] || (_cache[6] = [
            _createElementVNode("label", { class: "input-feedback red" }, "Name is not available", -1),
            _createElementVNode("label", { class: "input-feedback-icon red" }, "close", -1)
          ]), 512), [
            [_vShow, 
              _ctx.newName &&
              _ctx.newName.length > 5 &&
              !_ctx.nameIsUnique &&
              !_ctx.isLoading &&
              !_ctx.checkingName &&
              _ctx.newName != _ctx.userData.name
            ]
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_6, _cache[7] || (_cache[7] = [
            _createElementVNode("div", { class: "name-check-loading" }, null, -1)
          ]), 512), [
            [_vShow, _ctx.checkingName]
          ])
        ]),
        _withDirectives(_createElementVNode("div", {
          class: "btn w-button",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.confirmNameChange && _ctx.confirmNameChange(...args)))
        }, " Next ", 512), [
          [_vShow, 
            _ctx.newName &&
            !_ctx.isLoading &&
            _ctx.nameIsUnique &&
            _ctx.newName.length > 5 &&
            !_ctx.checkingName
          ]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_7, " Next ", 512), [
          [_vShow, 
            _ctx.newName &&
            !_ctx.isLoading &&
            (!_ctx.nameIsUnique || _ctx.newName.length <= 5 || _ctx.checkingName) &&
            _ctx.newName != _ctx.userData.name
          ]
        ]),
        _withDirectives(_createElementVNode("div", {
          class: "btn w-button",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.confirmNameChange && _ctx.confirmNameChange(...args)))
        }, " I Like This Name ", 512), [
          [_vShow, _ctx.newName == _ctx.userData.name]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_8, _cache[9] || (_cache[9] = [
          _createElementVNode("div", { class: "loading-name" }, null, -1)
        ]), 512), [
          [_vShow, _ctx.isLoading]
        ])
      ])
    ], 32),
    _createElementVNode("div", {
      class: _normalizeClass(["user-setup-div", { open: _ctx.showUserSetupLoading }])
    }, _cache[10] || (_cache[10] = [
      _createStaticVNode("<div class=\"logo-large with-text\"></div><div class=\"vertical-spacer\"></div><div class=\"welcome-text\">snippets &amp; endless possibilities</div><div class=\"vertical-spacer\"></div><div class=\"setup-animation\"></div><div class=\"account-setup-text\">Setting up your journey</div>", 6)
    ]), 2),
    (_ctx.showNotification)
      ? (_openBlock(), _createBlock(_component_Notification, {
          key: 0,
          options: _ctx.notificationOptions
        }, null, 8, ["options"]))
      : _createCommentVNode("", true)
  ]))
}