import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-loading-div" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "logo-large with-text" }, null, -1),
    _createElementVNode("div", { class: "app-loading-wrapper" }, [
      _createElementVNode("div", { class: "app-loading-animation effect" }),
      _createElementVNode("div", { class: "app-loading-text" }, "Loading")
    ], -1)
  ])))
}