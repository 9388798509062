<template>
  <div v-html="markdown.render(content)" style="width: 100%" />
</template>

<script lang="ts">
import router from "@/router";
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import MarkdownIt from "markdown-it";
import MarkdownItHighlightjs from "markdown-it-highlightjs";
import highlight from "highlight.js";
import "highlight.js/styles/atom-one-dark.css";

export default defineComponent({
  name: "MarkdownComp",
  data() {
    return {
      markdown: new MarkdownIt().use(MarkdownItHighlightjs),
    };
  },
  props: {
    content: {
      type: String,
      default: "",
    },
  },
  mounted() {
    highlight.highlightAll();
  },
  computed: {
    //
  },
  methods: {
    //
  },
});
</script>
