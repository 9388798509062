<template>
  <header-comp />
  <AppLoading v-if="appIsLoading" />
  <router-view />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import HeaderComp from "./components/HeaderComp.vue";
import AppLoading from "./components/AppLoading.vue";
import { mapState } from "vuex";

export default defineComponent({
  components: {
    HeaderComp,
    AppLoading,
  },
  methods: {
    //
  },
  async mounted() {
    //
  },
  computed: {
    ...mapState(["appIsLoading"]),
  },
});
</script>
<style>
.monaco-editor {
  height: 100%;
  position: absolute !important;
}
/*width*/
::-webkit-scrollbar {
  width: 10px;
}

/*track*/
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

/*thumb*/
::-webkit-scrollbar-thumb {
  background:rgb(138, 136, 136);
  border-radius:8px;
}

/*thumb hover*/
::-webkit-scrollbar-thumb:hover {
  background:rgba(164, 164, 164, 0.5);
}

/*Monaco Editor Font*/
#monacodiv div, #monacoDiffEditor div {
  font-family: monospace;
  font-size: unset; 
}
.editor-container.view-line {
  font-family: monospace;
  font-size: unset;
  letter-spacing:0.02
}
</style>
