import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "form-input-wrapper" }
const _hoisted_2 = { class: "script-tag-input-wrapper" }
const _hoisted_3 = {
  key: 0,
  class: "script-generating-loading"
}
const _hoisted_4 = { class: "gradient-wrapper" }
const _hoisted_5 = {
  key: 1,
  class: "btn script-copy done"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.lang == 'html')
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: _normalizeClass(["script-tag-label", { dev: _ctx.env == 'dev', prod: _ctx.env == 'prod' }])
        }, [
          _cache[8] || (_cache[8] = _createTextVNode(" HTML ")),
          _createElementVNode("span", {
            class: "script-copy-action-btn",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showUrl && _ctx.showUrl(...args)))
          }, "URL"),
          _createElementVNode("span", {
            class: "script-copy-action-btn",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.showIframe && _ctx.showIframe(...args)))
          }, "iFrame Element")
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.lang == 'css')
      ? (_openBlock(), _createElementBlock("label", {
          key: 1,
          class: _normalizeClass(["script-tag-label", { dev: _ctx.env == 'dev', prod: _ctx.env == 'prod' }])
        }, [
          _cache[9] || (_cache[9] = _createTextVNode(" CSS ")),
          _createElementVNode("span", {
            class: "script-copy-action-btn",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.showUrl && _ctx.showUrl(...args)))
          }, "URL"),
          _createElementVNode("span", {
            class: "script-copy-action-btn",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.showLinkTag && _ctx.showLinkTag(...args)))
          }, "HTML Link Tag")
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.lang == 'js')
      ? (_openBlock(), _createElementBlock("label", {
          key: 2,
          class: _normalizeClass(["script-tag-label", { dev: _ctx.env == 'dev', prod: _ctx.env == 'prod' }])
        }, [
          _cache[10] || (_cache[10] = _createTextVNode(" JavaScript ")),
          _createElementVNode("span", {
            class: "script-copy-action-btn",
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.showUrl && _ctx.showUrl(...args)))
          }, "URL"),
          _createElementVNode("span", {
            class: "script-copy-action-btn",
            onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.showScriptTag && _ctx.showScriptTag(...args)))
          }, "HTML Script Tag")
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        class: "form-input w-input",
        type: "text",
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.renderedUrl) = $event))
      }, null, 512), [
        [_vModelText, _ctx.renderedUrl]
      ]),
      (_ctx.showLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _cache[11] || (_cache[11] = _createElementVNode("div", { class: "gradient script-tag" }, null, -1)),
        (!_ctx.isCopied)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "btn script-copy",
              onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.copyInputText(_ctx.renderedUrl)))
            }, "Copy"))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, "Copied"))
      ])
    ])
  ]))
}