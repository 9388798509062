<template>

  <QuillEditor class="chirp-search-result-code-preview quill-editor" contentType="html" theme="snow"
    :toolbar="toolbarOptions" :content="content" @update:content="contentUpdated" />


</template>

<script lang="ts">
import { mapActions } from "vuex";
import { Delta, QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { defineComponent } from "vue";

export default defineComponent({
  name: "quillComp",
  components: {
    QuillEditor,
  },
  data() {
    return {
      toolbarOptions: [
        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],
        ['link', 'image', 'video', 'formula'],

        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean']                                         // remove formatting button
      ],
      quillToolbarHeight: 0,
    }
  },
  props: {
    content: {
      type: String,
      default: "",
    }
  },
  mounted() {
    // Auto resize editor height
    const quilEditorDiv = document.getElementsByClassName("ql-container")[0] as HTMLElement
    const quillToolbarDiv = document.getElementsByClassName("ql-toolbar")
    console.log(quillToolbarDiv[0].clientHeight);
    new ResizeObserver((e) => {
      console.log(e[0].contentRect.height)
      this.quillToolbarHeight = e[0].contentRect.height;
      quilEditorDiv.style.maxHeight = `calc(100% - ${this.quillToolbarHeight})`;
    }).observe(quillToolbarDiv[0]);
  },
  computed: {
    //
  },
  methods: {
    contentUpdated(content: string) {
      this.$emit('contentUpdated', content);
      //console.log(content);
    }
  },
});
</script>
<style>
.ql-toolbar.ql-snow {
  background-color: white;
}
code {
  -webkit-spell-checking: none;
  -moz-spell-checking: none;
  -ms-spell-checking: none;
  spellcheck: none;
}
</style>