import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "left-panel-list project" }
const _hoisted_2 = { class: "cdn-wrapper" }
const _hoisted_3 = { class: "cdn-form w-form" }
const _hoisted_4 = { class: "cdn-input-wrapper" }
const _hoisted_5 = { class: "cdn-input-gradient-holder" }
const _hoisted_6 = {
  key: 0,
  class: "cdn-list"
}
const _hoisted_7 = { class: "cdn-input-wrapper" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "cdn-input-gradient-holder" }
const _hoisted_10 = ["value"]
const _hoisted_11 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[7] || (_cache[7] = _createElementVNode("div", { class: "setting-section-title" }, [
      _createElementVNode("div", { class: "setting-text" }, "Project Settings")
    ], -1)),
    _cache[8] || (_cache[8] = _createElementVNode("div", { class: "setting-section-subtitle" }, [
      _createElementVNode("div", { class: "settings-subtitle" }, "CDN Managment")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("form", {
          class: "cdn-form-wrapper",
          onSubmit: _cache[3] || (_cache[3] = (e) => {
          e.preventDefault();
          _ctx.addCdn(_ctx.newCdnUrl); _ctx.newCdnUrl = ''
        })
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", {
              class: "btn cdn-btn",
              onClick: _cache[0] || (_cache[0] = ($event: any) => {_ctx.addCdn(_ctx.newCdnUrl); _ctx.newCdnUrl = ''})
            }, "add"),
            _createElementVNode("div", _hoisted_5, [
              _withDirectives(_createElementVNode("input", {
                class: "cdn-input w-input",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newCdnUrl) = $event)),
                placeholder: "Type a CDN URL",
                type: "text"
              }, null, 512), [
                [_vModelText, _ctx.newCdnUrl]
              ]),
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "cdn-input-gradient" }, null, -1))
            ])
          ]),
          (_ctx.selectedProject.cdnList)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedProject.cdnList, (cdn, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "cdn-input-container",
                    key: index
                  }, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", {
                        class: "btn cdn-btn red",
                        onClick: ($event: any) => (_ctx.cdnToDelete = index)
                      }, "delete", 8, _hoisted_8),
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("input", {
                          class: "cdn-input disabled w-input",
                          disabled: "",
                          value: cdn,
                          type: "text"
                        }, null, 8, _hoisted_10),
                        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "cdn-input-gradient disabled" }, null, -1))
                      ])
                    ]),
                    _createElementVNode("div", {
                      class: _normalizeClass(["cdn-delete-wrapper", { open: _ctx.cdnToDelete == index }])
                    }, [
                      _createElementVNode("div", {
                        class: "btn cdn-confirm red",
                        onClick: ($event: any) => (_ctx.deleteCdn(cdn))
                      }, "Delete", 8, _hoisted_11),
                      _createElementVNode("div", {
                        class: "btn cdn-confirm",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.cdnToDelete = -1))
                      }, "Cancel")
                    ], 2)
                  ]))
                }), 128)),
                _cache[6] || (_cache[6] = _createElementVNode("div", { class: "cdn-list-gradient" }, null, -1))
              ]))
            : _createCommentVNode("", true)
        ], 32)
      ])
    ]),
    _cache[9] || (_cache[9] = _createElementVNode("div", { class: "setting-section-subtitle" }, null, -1))
  ]))
}