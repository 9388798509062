import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_comp = _resolveComponent("header-comp")!
  const _component_AppLoading = _resolveComponent("AppLoading")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_header_comp),
    (_ctx.appIsLoading)
      ? (_openBlock(), _createBlock(_component_AppLoading, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view)
  ], 64))
}