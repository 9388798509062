<template>
  <div
    class="btn chirp-search"
    v-if="chirp"
    :class="{ selected: selectedChirp && selectedChirp.id == chirp.id }"
    @click="
      if (!selectedChirp || (selectedChirp && selectedChirp.id != chirp.id)) {
        setSelectedChirp(chirp);
      } else {
        setSelectedChirp(null);
      }
    "
  >
    {{ chirp.name }}
  </div>
</template>

<script lang="ts">
import router from "@/router";
import { defineComponent } from "vue";
import { mapActions, mapMutations, mapState } from "vuex";

export default defineComponent({
  name: "ChirpItem",
  data() {
    return {
      deleteConfirm: false,
    };
  },
  props: {
    chirp: {} as any,
  },
  computed: {
    ...mapState("modChirp", ["selectedChirp"]),
  },
  methods: {
    ...mapMutations("modChirp", ["setSelectedChirp"]),
    goToChirpPage(chirpId: string) {
      if (chirpId) router.push(`/chirp/${chirpId}`);
    },
  },
});
</script>
