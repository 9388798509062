<template>
  <div v-if="options" class="notifiction-div">
    <div class="notification-box">
      <div class="notification-title" :class="{ red: options.isError }">
        {{ options.title }}
      </div>
      <div class="notification-text">
        {{ options.text }}
      </div>
      <div class="btns-wrapper">
        <div
          class="notification-btn"
          @click="
            if (options.yesFunction != (null || undefined)) {
              options.yesFunction();
            } else {
              setShowNotification(false);
            }
          "
        >
          {{ options.yesText }}
        </div>
        <div
          v-if="options.question === true"
          class="notification-btn red"
          @click="
            if (options.noFunction != (null || undefined)) {
              options.noFunction();
            } else {
              setShowNotification(false);
            }
          "
        >
          {{ options.noText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
/**
Example:
  notificationOptions = {
    isError: true,
    question: false,
    yesText: "ok",
    noText: "",
    title: "Can't change your name",
    text: "Looks like we can't change your name at this moment.",
    yesFunction: () => {
      this.showNotification = false;
    },
    noFunction: null,
  };
*/
import { defineComponent } from "vue";
import { mapActions, mapMutations, mapState } from "vuex";

export default defineComponent({
  name: "ProjectCard",
  props: {
    options: Object,
    question: Boolean, // question | confirm
    yesText: String,
    noText: String,
    title: String,
    text: String,
    yesFunction: Function || null,
    noFunction: Function || null,
    isError: Boolean,
  },
  computed: {
    ...mapState(["showNotification"]),
  },
  methods: {
    ...mapMutations(["setShowNotification"]),
  },
});
</script>
