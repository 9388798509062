<template>
  <div class="editor-title" v-if="language == 'javascript'">Javascript
    <span style="color: orange; margin-left: 5px;" v-if="code != selectedProject.jsCode && code != ''">(Not
      Saved)</span>
  </div>
  <div class="editor-title" v-if="language == 'html'">HTML
    <span style="color: orange; margin-left: 5px;" v-if="code != selectedProject.htmlCode && code != ''">(Not
      Saved)</span>
  </div>
  <div class="editor-title" v-if="language == 'css'">CSS
    <span style="color: orange; margin-left: 5px;" v-if="code != selectedProject.cssCode && code != ''">(Not
      Saved)</span>
  </div>
  <div class="code-editor-holder" v-if="code != null">
    <VueMonacoEditor :theme="theme" :options="MONACO_EDITOR_OPTIONS" :language="language" @mount="handleMount"
      @change="codeUpdated" :value="code" />
  </div>
</template>

<script lang="ts">
import router from "@/router";
import { defineComponent, shallowRef } from "vue";
import { mapActions, mapMutations, mapState } from "vuex";
import * as monaco from "monaco-editor";
import { getDownloadURL, ref as storageRef, } from "firebase/storage";
import { db, storage } from "@/firebase-app";
import { doc, getDoc, setDoc } from "firebase/firestore";

export default defineComponent({
  name: "CodeEditor",
  emits: ['codeUpdated'],
  data() {
    return {
      code: null as unknown as string, // Initialize based on language
      editorRef: shallowRef(null) as any,
      MONACO_EDITOR_OPTIONS: {
        automaticLayout: true,
        formatOnType: true,
        formatOnPaste: true,
        folding: true,
      },
      changeTimeoutId: null as any,
    }
  },
  props: {
    theme: {
      type: String,
      default: "vs-dark",
    },
    language: {
      type: String,
    },
  },
  async mounted() {
    this.getInitialCode();
  },

  computed: {
    ...mapState("modLogin", ["user"]),
    ...mapState("modProjects", [
      "selectedProject",
    ]),
    ...mapState("modVersioning", ["selectedVersion"])
  },
  watch: {
    selectedVersion(newVal, oldVal) {
      if (newVal) {
        switch (this.language) {
          case "javascript":
            this.code = newVal.jsCode
            break
          case "css":
            this.code = newVal.cssCode
            break
          case "html":
            this.code = newVal.htmlCode
            break
        }
      } else {
        switch (this.language) {
          case "javascript":
            this.code = this.selectedProject.jsCode
            break
          case "css":
            this.code = this.selectedProject.cssCode
            break
          case "html":
            this.code = this.selectedProject.htmlCode
            break
        }
      }
    }
  },
  methods: {
    ...mapMutations("modProjects", [
      "setSelectedProject",
      "setSelectedProjectId",
      "setJsCode",
      "setHtmlCode",
      "setCssCode",
    ]),
    ...mapMutations("modVersioning", [
      "setJsCode",
      "setHtmlCode",
      "setCssCode",
    ]),
    ...mapMutations(["refreshPreview"]),

    getInitialCode() {
      switch (this.language) {
        case 'javascript':
          this.code = this.selectedProject?.jsCode || '';
          this.setJsCode(this.code)
          break
        case 'html':
          this.code = this.selectedProject?.htmlCode || '';
          this.setHtmlCode(this.code)
          break
        case 'css':
          this.code = this.selectedProject?.cssCode || '';
          this.setCssCode(this.code)
          break

      }
    },

    codeUpdated(code: string) {
      this.$emit('codeUpdated', code);
      //
      if (this.language)
        this.code = code;
      //console.log(content);
      /*
      clearTimeout(this.changeTimeoutId);
      //this.isSyncing = true;
      this.changeTimeoutId = setTimeout(async () => {
        await this.updateProject(code);
        this.refreshPreview();
      }, 5000);
      */
    },

    async updateProject(value: string) {
      console.log("updating code!")
      let targetCode
      if (this.language)
        switch (this.language) {
          case "javascript":
            targetCode = { jsCode: value }
            break
          case "css":
            targetCode = { cssCode: value }
            break
          case "html":
            targetCode = { htmlCode: value }
            break
        }
      try {
        const projectDoc = doc(db, `users/${this.user.uid}/projects/${this.selectedProject.id}`)
        await setDoc(projectDoc, targetCode, { merge: true })

        switch (this.language) {
          case "javascript":
            this.code = this.selectedProject.jsCode
            break
          case "css":
            this.code = this.selectedProject.cssCode
            break
          case "html":
            this.code = this.selectedProject.htmlCode
            break
        }
      } catch (error) {
        console.error('Error updating Firestore document:', error);
      }
    },
    handleMount(editor: any) {
      this.editorRef = editor;
      //console.log("Editor: ", editor);
      //console.log("Editor: ", editor.Range);
      //console.log("Editor: ", editor.getAction("editor.action.formatDocument"));
    },
  },
});
</script>
