import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "body" }
const _hoisted_2 = { class: "form-block w-form" }
const _hoisted_3 = { class: "form" }
const _hoisted_4 = { class: "login-form-inputs-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("body", _hoisted_1, [
    _cache[10] || (_cache[10] = _createElementVNode("div", { class: "big-header" }, "ChirpJS Login", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("form", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[5] || (_cache[5] = _createElementVNode("label", {
            for: "email-2",
            class: "input-label"
          }, "E-Mail", -1)),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.emailInput) = $event)),
            class: "input-text w-input",
            autofocus: "true",
            maxlength: "256",
            type: "email"
          }, null, 512), [
            [_vModelText, _ctx.emailInput]
          ]),
          _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
          _cache[7] || (_cache[7] = _createElementVNode("label", {
            for: "password",
            class: "input-label"
          }, "Password", -1)),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.passwordInput) = $event)),
            class: "input-text w-input",
            maxlength: "256",
            placeholder: "",
            type: "password"
          }, null, 512), [
            [_vModelText, _ctx.passwordInput]
          ])
        ]),
        _createElementVNode("div", {
          class: "btn w-button",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (
            _ctx.signInWithEmail({ email: _ctx.emailInput, password: _ctx.passwordInput })
          ))
        }, " Login "),
        _cache[8] || (_cache[8] = _createElementVNode("div", { class: "text-center" }, "Or", -1)),
        _createElementVNode("div", {
          class: "btn google w-button",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.signinWithGoogle && _ctx.signinWithGoogle(...args)))
        }, " Login With Google "),
        _cache[9] || (_cache[9] = _createElementVNode("div", { class: "register-text" }, "Don't have an account?", -1)),
        _createElementVNode("div", {
          type: "submit",
          class: "btn register w-button",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$router.push('/register')))
        }, " Register (Quick & Free) ")
      ])
    ])
  ]))
}