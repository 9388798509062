import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "body" }
const _hoisted_2 = { class: "workspace-wrapper" }
const _hoisted_3 = { class: "side-menu" }
const _hoisted_4 = { class: "left-panel" }
const _hoisted_5 = { class: "left-panel-list project" }
const _hoisted_6 = { class: "doc-staus-div" }
const _hoisted_7 = { class: "doc-status-wrapper" }
const _hoisted_8 = {
  key: 0,
  class: "doc-status red"
}
const _hoisted_9 = { class: "right-panel project" }
const _hoisted_10 = { class: "project-code-editor" }
const _hoisted_11 = {
  key: 0,
  class: "code-editors-container"
}
const _hoisted_12 = {
  key: 1,
  class: "code-lock"
}
const _hoisted_13 = { class: "version-preview-banner" }
const _hoisted_14 = {
  key: 0,
  class: "version-preview-text"
}
const _hoisted_15 = {
  key: 1,
  class: "version-preview-text"
}
const _hoisted_16 = {
  key: 0,
  class: "project-footer"
}
const _hoisted_17 = { class: "save-action-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AiChat = _resolveComponent("AiChat")!
  const _component_VersioningComp = _resolveComponent("VersioningComp")!
  const _component_quillComp = _resolveComponent("quillComp")!
  const _component_ProjectSettings = _resolveComponent("ProjectSettings")!
  const _component_SplitterPanel = _resolveComponent("SplitterPanel")!
  const _component_SplitterResizeHandle = _resolveComponent("SplitterResizeHandle")!
  const _component_CodeEditor = _resolveComponent("CodeEditor")!
  const _component_SplitterGroup = _resolveComponent("SplitterGroup")!
  const _component_PreviewComp = _resolveComponent("PreviewComp")!
  const _component_ScriptTagsComp = _resolveComponent("ScriptTagsComp")!
  const _component_NewChirpDialog = _resolveComponent("NewChirpDialog")!
  const _component_Notification = _resolveComponent("Notification")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: _normalizeClass(["side-menu-item", { selected: _ctx.projectMenuTab == 'AI' }]),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setProjectMenuTab('AI')))
        }, _cache[11] || (_cache[11] = [
          _createElementVNode("div", { class: "side-menu-item-icon" }, "neurology", -1)
        ]), 2),
        _createElementVNode("div", {
          class: _normalizeClass(["side-menu-item", { selected: _ctx.projectMenuTab == 'doc' }]),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setProjectMenuTab('doc')))
        }, _cache[12] || (_cache[12] = [
          _createElementVNode("div", { class: "side-menu-item-icon" }, "description", -1)
        ]), 2),
        _createElementVNode("div", {
          class: _normalizeClass(["side-menu-item", { selected: _ctx.projectMenuTab == 'versioning' }]),
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setProjectMenuTab('versioning')))
        }, _cache[13] || (_cache[13] = [
          _createElementVNode("div", { class: "side-menu-item-icon" }, "folder_data", -1)
        ]), 2),
        _createElementVNode("div", {
          class: _normalizeClass(["side-menu-item", { selected: _ctx.projectMenuTab == 'settings' }]),
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setProjectMenuTab('settings')))
        }, _cache[14] || (_cache[14] = [
          _createElementVNode("div", { class: "side-menu-item-icon" }, "settings", -1)
        ]), 2)
      ]),
      _createVNode(_component_SplitterGroup, {
        id: "splitter-group-3",
        direction: "horizontal"
      }, {
        default: _withCtx(() => [
          _withDirectives(_createVNode(_component_SplitterPanel, {
            id: "splitter-group-3-panel-1",
            "min-size": 20,
            "default-size": 25,
            class: "SplitterPanel"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                (_ctx.selectedProject)
                  ? _withDirectives((_openBlock(), _createBlock(_component_AiChat, { key: 0 }, null, 512)), [
                      [_vShow, _ctx.projectMenuTab == 'AI']
                    ])
                  : _createCommentVNode("", true),
                (_ctx.projectMenuTab == 'versioning')
                  ? (_openBlock(), _createBlock(_component_VersioningComp, { key: 1 }))
                  : _createCommentVNode("", true),
                _withDirectives(_createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      (_ctx.initProjectDoc != _ctx.newProjectDoc)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, "Not Saved"))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", {
                        class: "btn project-control doc-save",
                        onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.setDocumentation && _ctx.setDocumentation(...args)))
                      }, "Save")
                    ])
                  ]),
                  _createVNode(_component_quillComp, {
                    content: _ctx.initProjectDoc,
                    onContentUpdated: _cache[5] || (_cache[5] = (content) => {
                _ctx.newProjectDoc = content
              })
                  }, null, 8, ["content"])
                ], 512), [
                  [_vShow, _ctx.projectMenuTab == 'doc']
                ]),
                (_ctx.projectMenuTab == 'settings')
                  ? (_openBlock(), _createBlock(_component_ProjectSettings, { key: 2 }))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }, 512), [
            [_vShow, _ctx.projectMenuTab]
          ]),
          _createVNode(_component_SplitterResizeHandle, {
            id: "splitter-group-3-resize-handle-1",
            class: "SplitterResizeHandle"
          }),
          _createVNode(_component_SplitterPanel, {
            id: "splitter-group-3-panel-2",
            "min-size": 5,
            class: "SplitterPanel"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  (_ctx.selectedProject)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                        _createVNode(_component_SplitterGroup, {
                          id: "splitter-group-1",
                          direction: "vertical"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_SplitterPanel, {
                              id: "splitter-group-1-panel-1",
                              "min-size": 5,
                              class: "SplitterPanel"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_SplitterGroup, {
                                  id: "splitter-group-2",
                                  direction: "horizontal"
                                }, {
                                  default: _withCtx(() => [
                                    _withDirectives(_createVNode(_component_SplitterPanel, {
                                      id: "splitter-group-2-panel-1",
                                      "min-size": 5,
                                      class: "SplitterPanel"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_CodeEditor, {
                                          language: 'html',
                                          onCodeUpdated: _cache[6] || (_cache[6] = (code) => {
                          _ctx.setHtmlCode(code);
                        })
                                        })
                                      ]),
                                      _: 1
                                    }, 512), [
                                      [_vShow, _ctx.htmlEditorView]
                                    ]),
                                    _withDirectives(_createVNode(_component_SplitterResizeHandle, {
                                      id: "splitter-group-2-resize-handle-1",
                                      class: "SplitterResizeHandle"
                                    }, null, 512), [
                                      [_vShow, _ctx.htmlEditorView]
                                    ]),
                                    _withDirectives(_createVNode(_component_SplitterPanel, {
                                      id: "splitter-group-2-panel-2",
                                      "min-size": 5,
                                      class: "SplitterPanel"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_CodeEditor, {
                                          language: 'css',
                                          onCodeUpdated: _cache[7] || (_cache[7] = (code) => {
                          _ctx.setCssCode(code)
                        })
                                        })
                                      ]),
                                      _: 1
                                    }, 512), [
                                      [_vShow, _ctx.cssEditorView]
                                    ]),
                                    _withDirectives(_createVNode(_component_SplitterResizeHandle, {
                                      id: "splitter-group-2-resize-handle-2",
                                      class: "SplitterResizeHandle"
                                    }, null, 512), [
                                      [_vShow, _ctx.cssEditorView]
                                    ]),
                                    _withDirectives(_createVNode(_component_SplitterPanel, {
                                      id: "splitter-group-2-panel-3",
                                      "min-size": 5,
                                      class: "SplitterPanel"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_CodeEditor, {
                                          language: 'javascript',
                                          onCodeUpdated: _cache[8] || (_cache[8] = (code) => {
                          _ctx.setJsCode(code)
                        })
                                        })
                                      ]),
                                      _: 1
                                    }, 512), [
                                      [_vShow, _ctx.jsEditorView]
                                    ])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_SplitterResizeHandle, {
                              id: "splitter-group-1-resize-handle-1",
                              class: "SplitterResizeHandle"
                            }),
                            _createVNode(_component_SplitterPanel, {
                              id: "splitter-group-1-panel-2",
                              "min-size": 5,
                              class: "SplitterPanel"
                            }, {
                              default: _withCtx(() => [
                                (_ctx.selectedProject)
                                  ? (_openBlock(), _createBlock(_component_PreviewComp, { key: _ctx.previewKey }))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.projectMenuTab == 'versioning')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        _createElementVNode("div", _hoisted_13, [
                          (_ctx.selectedVersion)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_14, " You are previewing version " + _toDisplayString(_ctx.selectedVersion.number), 1))
                            : (_openBlock(), _createElementBlock("div", _hoisted_15, " You are previewing your current work. ")),
                          _createElementVNode("div", {
                            class: "version-preview-text btn",
                            onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.setProjectMenuTab(_ctx.lastTab)))
                          }, " Exit Preview ")
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                (_ctx.projectMenuTab == 'chirps' || _ctx.projectMenuTab == 'AI' || _ctx.projectMenuTab == 'preview')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                      _createElementVNode("div", _hoisted_17, [
                        _createElementVNode("div", {
                          class: _normalizeClass(["btn save", { saving: _ctx.saving }]),
                          onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.saveCode && _ctx.saveCode(...args)))
                        }, " Save ", 2),
                        _createElementVNode("img", {
                          src: "https://cdn.prod.website-files.com/66b2a9b55aec6503eeabce5c/66e8457dc8ac9ca8ab5ec740_d158b93b-bded-4b54-ad27-8d22879a5fc2.svg",
                          loading: "lazy",
                          alt: "",
                          class: _normalizeClass(["save-animation", { saving: _ctx.saving }])
                        }, null, 2),
                        _createElementVNode("div", {
                          class: _normalizeClass(["project-save-count", { saving: _ctx.saving }])
                        }, " Saving ", 2)
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    (_ctx.showScriptTags)
      ? (_openBlock(), _createBlock(_component_ScriptTagsComp, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.newChirpDialog)
      ? (_openBlock(), _createBlock(_component_NewChirpDialog, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.showNotification)
      ? (_openBlock(), _createBlock(_component_Notification, {
          key: 2,
          options: _ctx.notificationOptions
        }, null, 8, ["options"]))
      : _createCommentVNode("", true)
  ]))
}