import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "header-logo-div" }
const _hoisted_3 = {
  key: 0,
  class: "project-controls"
}
const _hoisted_4 = {
  key: 1,
  class: "project-controls"
}
const _hoisted_5 = { class: "header-right-side-wrapper" }
const _hoisted_6 = {
  key: 0,
  class: "user-letter-icon"
}
const _hoisted_7 = {
  key: 1,
  class: "user-letter-icon"
}
const _hoisted_8 = { class: "header-menu" }
const _hoisted_9 = {
  key: 0,
  class: "header-menu-item"
}
const _hoisted_10 = {
  key: 1,
  class: "header-menu-item"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "header-logo",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setSelectedProjectId(null), _ctx.$router.push('/')))
      }),
      (typeof _ctx.selectedProjectId === 'string')
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "project-title",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (console.log(_ctx.selectedProject)))
          }, _toDisplayString(_ctx.selectedProjectId), 1))
        : _createCommentVNode("", true)
    ]),
    (_ctx.$router.currentRoute.value.path.includes('/project'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", {
            class: _normalizeClass(["project-control-button", { disabled: !_ctx.htmlEditorView }]),
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleHtmlEditor && _ctx.toggleHtmlEditor(...args)))
          }, " html ", 2),
          _createElementVNode("div", {
            class: _normalizeClass(["project-control-button", { disabled: !_ctx.cssEditorView }]),
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.toggleCssEditor && _ctx.toggleCssEditor(...args)))
          }, " css ", 2),
          _createElementVNode("div", {
            class: _normalizeClass(["project-control-button", { disabled: !_ctx.jsEditorView }]),
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.toggleJsEditor && _ctx.toggleJsEditor(...args)))
          }, " js ", 2)
        ]))
      : _createCommentVNode("", true),
    (_ctx.$router.currentRoute.value.path.includes('/preview'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", {
            class: "project-control-button",
            onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.refreshPreview && _ctx.refreshPreview(...args)))
          }, _cache[10] || (_cache[10] = [
            _createElementVNode("span", { class: "icon" }, "refresh", -1),
            _createTextVNode("Refresh")
          ]))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", {
        class: "header-user-wrapper",
        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.openMenu = !_ctx.openMenu))
      }, [
        _cache[11] || (_cache[11] = _createElementVNode("div", { class: "user-expand-btn" }, "expand_more", -1)),
        (_ctx.user && _ctx.userData)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.userData.name), 1))
          : (_openBlock(), _createElementBlock("div", _hoisted_7, "?"))
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["header-menu-bg", { open: _ctx.openMenu }]),
      onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.openMenu = !_ctx.openMenu))
    }, [
      _createElementVNode("div", _hoisted_8, [
        (_ctx.user && _ctx.userData)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, "Account"))
          : _createCommentVNode("", true),
        _cache[12] || (_cache[12] = _createElementVNode("div", { class: "header-menu-item" }, "Plans", -1)),
        (_ctx.user && _ctx.userData)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, "Billing"))
          : _createCommentVNode("", true),
        _cache[13] || (_cache[13] = _createElementVNode("div", { class: "header-menu-item" }, "Privacy Policy", -1)),
        _cache[14] || (_cache[14] = _createElementVNode("div", { class: "header-menu-item" }, "Terms & Conditions", -1)),
        (_ctx.user && _ctx.userData)
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: "header-menu-item",
              onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.signOut && _ctx.signOut(...args)))
            }, " Logout "))
          : _createCommentVNode("", true),
        (!_ctx.user)
          ? (_openBlock(), _createElementBlock("div", {
              key: 3,
              class: "header-menu-item",
              onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$router.push('/')))
            }, " Login "))
          : _createCommentVNode("", true)
      ])
    ], 2)
  ]))
}