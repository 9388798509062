<template>
  <div class="header-spacer"></div>
    <iframe class="preview-iframe"
      v-if="selectedProject" :srcdoc="content">
    </iframe>

 
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PreviewComp from "@/components/PreviewComp.vue";


import { mapActions, mapMutations, mapState } from "vuex";
import { useRoute } from "vue-router";
export default defineComponent({
  name: "PreviewView",
  components: {
    PreviewComp,
  },
  data() {
    return {
      projectId: "" as string | string[],
      content: ""
    }
  },
  setup() {
    //
  },
  async mounted() {
    const route = useRoute();
    this.projectId = route.params.id;
    if (this.projectId) {
      this.getProject(this.projectId)

    }
  },
  computed: {
    ...mapState("modLogin", ["user", "userData"]),
    ...mapState("modProjects", ["selectedProject"]),
  },
  watch: {
    selectedProject(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.content = `
          <html>
            <head>
              <style>
                ${this.selectedProject.cssCode}
              <\/style>
            <\/head>
            <body>
              ${this.selectedProject.htmlCode}
              <script type="text/javascript">
                ${this.selectedProject.jsCode}
              <\/script>
            <\/body>
          <\/html>
          `
      }
    }
  },
  methods: {
    ...mapMutations(["setShowNotification"]),
    ...mapActions("modProjects", ["getProject"]),
  },
});
</script>
