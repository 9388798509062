<template>
  <div class="workspace-item-wrapper chirp">
    <div class="item-icon active">cheer</div>
    <div class="item-detail-wrapper" @click="goToChirpPage(chirp.id)">
      <div class="item-details title">
        {{ chirp.name }}
      </div>
      <div class="item-details light">By {{ chirp.author }}</div>
      <div class="item-details light bold">
        Updated: {{ new Date(chirp.updatedDate).toLocaleString() }}
      </div>
    </div>
    <div class="item-icon" @click="showSettings = true">settings</div>
    <div class="item-options" v-if="showSettings">
      <div class="item-option">Rename</div>
      <div class="item-option">Unpublish</div>
      <div class="item-option red" @click="deleteQuestion = true">Delete</div>
      <div class="item-option-close-btn" @click="showSettings = false">
        close
      </div>
    </div>
    <div class="item-options" v-if="showSettings && deleteQuestion">
      <div class="item-option" @click="deleteQuestion = false">Cancel</div>
      <div class="item-option red" @click="deleteChirp(chirp.id)">Delete</div>
    </div>
  </div>
</template>

<script lang="ts">
import router from "@/router";
import { defineComponent } from "vue";
import { mapActions } from "vuex";

export default defineComponent({
  name: "ChirpCard",
  data() {
    return {
      showSettings: false,
      deleteQuestion: false,
    };
  },
  props: {
    chirp: {} as any,
  },
  computed: {
    //
  },
  methods: {
    ...mapActions("modChirp", ["deleteChirp"]),
    goToChirpPage(chirpId: string) {
      if (chirpId) router.push(`/chirp/${chirpId}`);
    },
  },
});
</script>
