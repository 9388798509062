<template>
  <div class="body">
    <div class="workspace-wrapper" v-if="userData">
      <div class="left-panel workspace" style="width: 300px; padding-right: 40px;">
        <div class="worspace-name-wrapper">
          <div class="normal-icon">dashboard</div>
          <div class="workspace-name">{{ userData.name }}'s Workspace</div>
        </div>
        <div class="btn big" @click="showNewProject = true">
          Start a new project
        </div>
        <br />
        <div class="left-panel-list workspace">
          <div class="left-panel-list-item" :class="{ selected: workspaceNav == 'projects' }"
            @click="setWorkspaceNav('projects')">
            Projects ({{ userProjects.length }})
          </div>
          <div class="left-panel-list-divider"></div>
          <div class="left-panel-list-item" :class="{ selected: workspaceNav == 'chirps' }"
            @click="setWorkspaceNav('chirps')">
            Chirps ({{ chirps.length }})
          </div>
          <div class="left-panel-list-divider"></div>
          <div class="left-panel-list-item" :class="{ selected: workspaceNav == 'tutorials' }"
            @click="setWorkspaceNav('tutorials')">
            Tutorials
          </div>
        </div>
      </div>
      <div class="right-panel">
        <div class="workspace-search-container hidden">
          <div class="workspace-search-form-wrapper w-form">
            <form id="email-form" name="email-form" data-name="Email Form" method="get" class="workspace-search-form"
              data-wf-page-id="66b2ac9bfe9ffcb604182671" data-wf-element-id="8c83358b-e6e6-3b7f-f56f-abaefb9f47ab"
              aria-label="Email Form">
              <input class="workspace-search-input w-input" maxlength="256" name="name" data-name="Name"
                placeholder="Search Projects &amp; Tutorials" type="text" id="name" />
              <div class="workspace-search-input-btn">search</div>
            </form>
            <div class="w-form-done" tabindex="-1" role="region" aria-label="Email Form success">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div class="w-form-fail" tabindex="-1" role="region" aria-label="Email Form failure">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
        </div>
        <div class="workspace-items-container" v-if="workspaceNav == 'projects'">
          <div class="right-panel-title">Projects</div>
          <ProjectCard v-for="project in userProjects" :key="project.name" :project="project" />
          <div class="workspace-items-container" v-if="userProjects.length == 0">
            <div class="empty-project-list">No Projects Yet</div>
            <div class="empty-projects-btn-wrapper">
              <div class="btn big limit-width" @click="showNewProject = true">
                Start a new project
              </div>
            </div>
          </div>
        </div>
        <div class="workspace-items-container" v-if="workspaceNav == 'chirps'">
          <div class="right-panel-title">Chirps</div>
          <div class="workspace-item-wrapper new-chirp" @click="setNewChirpDialog(true)">
            <div class="item-icon active">add_circle</div>
            <div class="item-detail-wrapper">
              <div class="item-details center">Create a new chirp</div>
            </div>
            <div class="item-options hidden">
              <div class="item-option">Rename</div>
              <div class="item-option">Unpublish</div>
              <div class="item-option red">Delete</div>
              <div class="item-option-close-btn">close</div>
            </div>
          </div>
          <ChirpCard v-for="chirp in chirps" :key="chirp.name" :chirp="chirp" />
        </div>
      </div>
    </div>
    <div class="new-project-div" :class="{ open: showNewProject }">
      <div class="new-project-form-title">
        <span class="new-project-icon">family_star</span> New ChirpJS Project
      </div>
      <br />
      <div class="new-project-form w-form">
        <form name="project-form" @submit="(e) => {
          e.preventDefault();
          createProject();
        }">
          <div class="form-input-wrapper">
            <label for="project-title" class="form-label">Project Name</label>
            <input v-model="newProjectName" class="form-input w-input" maxlength="256" name="project-title"
              placeholder="" type="text" />
          </div>
        </form>
      </div>
      <br />
      <div class="btns-wrapper">
        <div class="notification-btn" @click="createProject">Create</div>
        <div class="notification-btn red" @click="showNewProject = false">
          Discard
        </div>
      </div>
      <div class="error-text" v-if="errorMessage.length > 0">
        {{ errorMessage }}
      </div>
    </div>
    <new-chirp-dialog v-if="newChirpDialog" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapActions, mapMutations, mapState } from "vuex";
import {
  getCountFromServer,
  query,
  collection,
  documentId,
} from "@firebase/firestore";
import { db } from "@/firebase-app";
import { addDoc, doc, setDoc, where } from "firebase/firestore";
import ProjectCard from "@/components/ProjectCard.vue";
import ChirpCard from "@/components/ChirpCard.vue";
import router from "@/router";
import NewChirpDialog from "@/components/NewChirpDialog.vue";
import store from "@/store";

export default defineComponent({
  name: "WorkspaceView",
  components: {
    ProjectCard,
    ChirpCard,
    NewChirpDialog,
  },
  data() {
    return {
      showNewProject: false,
      newProjectName: "",
      projectExistFlag: false,
      errorMessage: "",
      chirpExistFlag: false,
    };
  },
  setup() {
    //
  },
  mounted() {
    // get user projects
    this.fetchUserProjects(this.user.uid);
    this.fetchUserChirps(this.user.uid);
  },
  computed: {
    ...mapState(["workspaceNav"]),
    ...mapState("modLogin", ["user", "userData"]),
    ...mapState("modProjects", ["userProjects"]),
    ...mapState("modChirp", ["newChirpDialog", "chirps"]),
  },
  methods: {
    ...mapMutations(["setWorkspaceNav"]),
    ...mapMutations("modChirp", ["setNewChirpDialog"]),
    ...mapMutations("modProjects", ["setSelectedProject"]),
    ...mapActions("modProjects", ["fetchUserProjects"]),
    ...mapActions("modChirp", ["fetchUserChirps"]),

    async projectExist(name: string) {
      const q = query(
        collection(db, `users/${this.user.uid}/projects`),
        where(documentId(), "==", name)
      );
      const snap = await getCountFromServer(q);
      console.log("CHECKING FOR EXISTING PROJECT");
      if (snap.data().count > 0) {
        console.log("PROJECT WITH THE SAME NAME EXISTS");
        this.projectExistFlag = true;
        this.errorMessage = `Project ${name} already exists`;
        return true;
      } else {
        console.log("PROJECT WITH THE SAME NAME DOES NOT EXIST");
        this.projectExistFlag = false;
        this.errorMessage = "";
        return false;
      }
    },
    async createProject() {
      store.commit("setAppIsLoading", true);
      console.log("CREATING PROJECT...");
      if (this.newProjectName.length < 5) {
        this.errorMessage = `Project name is too short (less than 5 letters)`;
        store.commit("setAppIsLoading", false);
        console.log("PROJECT NAME IS TOO SHORT");
        return;
      }
      const projectName = this.newProjectName.replace(/ /g, "-").toLowerCase();
      const projectExist = await this.projectExist(projectName);
      if (!projectExist) {
        const jsCode = ` `
        const cssCode = ` `
        const htmlCode = ` `
        const project = {
          name: projectName,
          createdDate: Date.now(),
          updatedDate: Date.now(),
          uid: this.user.uid,
          author: this.user.displayName,
          jsCode: jsCode,
          cssCode: cssCode,
          htmlCode: htmlCode,
          cdnList: [],
        };

        const projectDoc = doc(
          db,
          `users/${this.user.uid}/projects`,
          projectName
        );

        await setDoc(projectDoc, project)
          .then(async () => {
            console.log(`Project created with ID: ${projectName}`);            
            this.setSelectedProject(project)
            this.errorMessage = "";
          })
          .catch((error) => {
            console.error("Error creating project:", error);
          });



        router.push(`/project/${projectName}`);
        this.showNewProject = false;
        store.commit("setAppIsLoading", false);
      } else {
        this.showNewProject = false;
        store.commit("setAppIsLoading", false);
      }

    },

    async chirpExist(name: string) {
      const q = query(
        collection(db, `users/${this.user.uid}/chirps`),
        where(documentId(), "==", name)
      );
      const snap = await getCountFromServer(q);
      console.log("CHECKING FOR EXISTING PROJECT");
      if (snap.data().count > 0) {
        console.log("CHIRP WITH THE SAME NAME EXISTS");
        this.chirpExistFlag = true;
        this.errorMessage = `chirp ${name} already exists`;
        return true;
      } else {
        console.log("CHIRP WITH THE SAME NAME DOES NOT EXIST");
        this.chirpExistFlag = false;
        this.errorMessage = "";
        return false;
      }
    },
  },
});
</script>
