import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tags-div"
}
const _hoisted_2 = { class: "script-tags-box" }
const _hoisted_3 = { class: "script-tags-box-title" }
const _hoisted_4 = { class: "script-tags-toggle" }
const _hoisted_5 = { class: "script-tag-actions-wrapper" }
const _hoisted_6 = {
  key: 1,
  class: "btn move-to-dev disabled"
}
const _hoisted_7 = {
  key: 0,
  class: "scripts-tags-form"
}
const _hoisted_8 = {
  key: 1,
  style: {"width":"500px"}
}
const _hoisted_9 = { class: "script-tag-actions-wrapper" }
const _hoisted_10 = {
  key: 1,
  class: "btn move-to-prod disabled"
}
const _hoisted_11 = {
  key: 0,
  class: "scripts-tags-form"
}
const _hoisted_12 = {
  key: 1,
  style: {"width":"500px"}
}
const _hoisted_13 = { class: "script-tags-box-btn-wrapper" }
const _hoisted_14 = {
  key: 0,
  class: "code-upload-loading-wrapper"
}
const _hoisted_15 = {
  key: 1,
  class: "code-not-saved-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScriptTagOutput = _resolveComponent("ScriptTagOutput")!
  const _component_Notification = _resolveComponent("Notification")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.selectedVersion !== null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _cache[6] || (_cache[6] = _createElementVNode("strong", null, "Scripts Tags", -1)),
              _createTextVNode(" (Version: " + _toDisplayString(_ctx.selectedVersion.number) + ")", 1)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", {
                class: _normalizeClass(["script-tag-toggle-btn", { 'selected-dev': _ctx.selectedEnv == 'dev' }]),
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectedEnv = 'dev'))
              }, " Development Scripts", 2),
              _createElementVNode("div", {
                class: _normalizeClass(["script-tag-toggle-btn", { 'selected': _ctx.selectedEnv == 'prod' }]),
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectedEnv = 'prod'))
              }, " Production Scripts", 2)
            ]),
            _createElementVNode("div", {
              class: _normalizeClass(["scripts-form-block dev w-form", { open: _ctx.selectedEnv == 'dev' }])
            }, [
              _createElementVNode("div", _hoisted_5, [
                (_ctx.selectedProject.devVersion !== _ctx.selectedVersion.id)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "btn move-to-dev",
                      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.publishToDev && _ctx.publishToDev(...args)))
                    }, _cache[7] || (_cache[7] = [
                      _createElementVNode("div", { class: "normal-icon" }, "done", -1),
                      _createElementVNode("div", { class: "btn-text" }, "Publish to Development", -1)
                    ])))
                  : (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[8] || (_cache[8] = [
                      _createElementVNode("div", { class: "normal-icon" }, "done", -1),
                      _createElementVNode("div", { class: "btn-text" }, "Already Published", -1)
                    ])))
              ]),
              (_ctx.showDevOutput)
                ? (_openBlock(), _createElementBlock("form", _hoisted_7, [
                    _createVNode(_component_ScriptTagOutput, {
                      env: 'dev',
                      lang: 'html'
                    }),
                    _createVNode(_component_ScriptTagOutput, {
                      env: 'dev',
                      lang: 'css'
                    }),
                    _createVNode(_component_ScriptTagOutput, {
                      env: 'dev',
                      lang: 'js'
                    })
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_8))
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["scripts-form-block prod w-form", { open: _ctx.selectedEnv == 'prod' }])
            }, [
              _createElementVNode("div", _hoisted_9, [
                (_ctx.selectedProject.prodVersion !== _ctx.selectedVersion.id)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "btn move-to-prod",
                      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.moveToProd && _ctx.moveToProd(...args)))
                    }, _cache[9] || (_cache[9] = [
                      _createElementVNode("div", { class: "normal-icon" }, "done", -1),
                      _createElementVNode("div", { class: "btn-text" }, "Move to Production", -1)
                    ])))
                  : (_openBlock(), _createElementBlock("div", _hoisted_10, _cache[10] || (_cache[10] = [
                      _createElementVNode("div", { class: "normal-icon" }, "done", -1),
                      _createElementVNode("div", { class: "btn-text" }, "Already in Production", -1)
                    ])))
              ]),
              (_ctx.showProdOutput)
                ? (_openBlock(), _createElementBlock("form", _hoisted_11, [
                    _createVNode(_component_ScriptTagOutput, {
                      env: 'prod',
                      lang: 'html'
                    }),
                    _createVNode(_component_ScriptTagOutput, {
                      env: 'prod',
                      lang: 'css'
                    }),
                    _createVNode(_component_ScriptTagOutput, {
                      env: 'prod',
                      lang: 'js'
                    })
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_12))
            ], 2),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", {
                class: "notification-btn",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setShowScriptTags(false)))
              }, "Hide")
            ]),
            (_ctx.showLoading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, _cache[11] || (_cache[11] = [
                  _createElementVNode("div", { class: "code-upload-loading" }, null, -1),
                  _createElementVNode("div", { class: "text-center" }, "Publishing", -1)
                ])))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.selectedVersion == null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
          _cache[12] || (_cache[12] = _createElementVNode("div", { class: "welcome-text" }, "Code is not versioned. Create a new version or select one from the versions library ", -1)),
          _cache[13] || (_cache[13] = _createElementVNode("div", { class: "notification-btn blue" }, "Create a new Version", -1)),
          _cache[14] || (_cache[14] = _createElementVNode("br", null, null, -1)),
          _createElementVNode("div", {
            class: "notification-btn disabled",
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.setShowScriptTags(false)))
          }, "Cancel")
        ]))
      : _createCommentVNode("", true),
    (_ctx.showNotification)
      ? (_openBlock(), _createBlock(_component_Notification, {
          key: 2,
          options: _ctx.notificationOptions
        }, null, 8, ["options"]))
      : _createCommentVNode("", true)
  ], 64))
}