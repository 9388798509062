import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withModifiers as _withModifiers, withKeys as _withKeys, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "left-panel-list project ai" }
const _hoisted_2 = { class: "ai-tab-header-div" }
const _hoisted_3 = { class: "ai-header-text details" }
const _hoisted_4 = { class: "ai-comp-title-row" }
const _hoisted_5 = { class: "ai-comp-title-col" }
const _hoisted_6 = { class: "ai-comp-title-col" }
const _hoisted_7 = { class: "chirp-result-details" }
const _hoisted_8 = {
  key: 0,
  class: "ai-update-form w-form"
}
const _hoisted_9 = {
  class: "chirp-search-form-wrapper",
  style: {"grid-row-gap":"0"}
}
const _hoisted_10 = { class: "chirp-search-input-wrapper" }
const _hoisted_11 = { class: "dropdown-with-label" }
const _hoisted_12 = { class: "dropdown-label" }
const _hoisted_13 = { class: "dropdown-input full-width" }
const _hoisted_14 = {
  key: 0,
  class: "dropdown-items-wrapper"
}
const _hoisted_15 = ["onClick"]
const _hoisted_16 = {
  class: "chirp-search-form-wrapper",
  style: {"grid-row-gap":"0"}
}
const _hoisted_17 = { class: "chirp-search-input-wrapper" }
const _hoisted_18 = {
  class: "chirp-search-form-wrapper",
  style: {"grid-row-gap":"0"}
}
const _hoisted_19 = { class: "chirp-search-input-wrapper" }
const _hoisted_20 = { class: "ai-apli-update-btns-wrapper" }
const _hoisted_21 = {
  key: 0,
  class: "api-updating-loading"
}
const _hoisted_22 = {
  key: 0,
  class: "ai-tab-header-div"
}
const _hoisted_23 = { class: "ai-actions-wrapper" }
const _hoisted_24 = { class: "dropdown-input" }
const _hoisted_25 = {
  key: 0,
  class: "dropdown-items-wrapper"
}
const _hoisted_26 = ["onClick", "title"]
const _hoisted_27 = {
  key: 0,
  class: "dropdown-item"
}
const _hoisted_28 = ["title"]
const _hoisted_29 = { class: "ai-chat-actions-wrapper" }
const _hoisted_30 = {
  key: 1,
  class: "btn project-control icon full-width disabled",
  title: "No Selected Chat to Delete"
}
const _hoisted_31 = {
  key: 0,
  class: "ai-actions-wrapper"
}
const _hoisted_32 = {
  key: 1,
  class: "ai-tab-chat-wrapper"
}
const _hoisted_33 = { class: "ai-ask-buttons" }
const _hoisted_34 = {
  class: "ai-chat-window",
  ref: "scrollContainer"
}
const _hoisted_35 = {
  key: 0,
  class: "ai-chat-block-wrapper"
}
const _hoisted_36 = ["innerHTML"]
const _hoisted_37 = {
  key: 1,
  class: "ai-chat-block-wrapper ai"
}
const _hoisted_38 = ["onClick"]
const _hoisted_39 = {
  key: 0,
  class: "ai-chat-block-wrapper ai"
}
const _hoisted_40 = { class: "chirp-search-form-wrapper" }
const _hoisted_41 = { class: "ai-chat-input-wrapper" }
const _hoisted_42 = {
  key: 2,
  class: "ai-api-key-setup"
}
const _hoisted_43 = { class: "chirp-search-input-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Markdown = _resolveComponent("Markdown")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", {
              class: "chirp-result-title",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (console.log(_ctx.messages)))
            }, "AI Assistance"),
            _cache[28] || (_cache[28] = _createElementVNode("div", { class: "chirp-result-author" }, "Local Ollama", -1))
          ]),
          _createElementVNode("div", _hoisted_6, [
            (!_ctx.showSettings)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "btn project-control full-width",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => {_ctx.showSettings = true; _ctx.newSystemPrompt = _ctx.systemPrompt})
                }, " Settings "))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _cache[29] || (_cache[29] = _createTextVNode("Running Locally on ")),
          _cache[30] || (_cache[30] = _createElementVNode("a", {
            href: "https://ollama.com/",
            target: "_blank",
            class: "google-ai-link"
          }, "Ollama", -1)),
          _cache[31] || (_cache[31] = _createTextVNode(". Selected LLM Model is ")),
          _createElementVNode("strong", null, _toDisplayString(_ctx.selectedLlmModel), 1)
        ])
      ]),
      (_ctx.showSettings)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _cache[38] || (_cache[38] = _createElementVNode("div", { style: {"height":"10px"} }, null, -1)),
            _createElementVNode("form", _hoisted_9, [
              _cache[32] || (_cache[32] = _createElementVNode("div", { class: "dropdown-label" }, "Ollama Host Url:", -1)),
              _createElementVNode("div", _hoisted_10, [
                _withDirectives(_createElementVNode("input", {
                  class: "chirp-search-input w-input",
                  placeholder: "Ollama Host Url",
                  type: "text",
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newHostUrl) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.newHostUrl]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("span", {
                  class: "inline-icon",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => {_ctx.getChatList();})
                }, "refresh"),
                _cache[33] || (_cache[33] = _createTextVNode("Available Models: (")),
                _cache[34] || (_cache[34] = _createElementVNode("a", {
                  href: "https://ollama.com/",
                  target: "_blank",
                  class: "google-ai-link"
                }, "Download Models", -1)),
                _cache[35] || (_cache[35] = _createTextVNode(") "))
              ]),
              _createElementVNode("div", _hoisted_13, [
                (
              _ctx.showModelsList)
                  ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_14, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelsList, (model, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "dropdown-item",
                          key: index,
                          onClick: ($event: any) => {_ctx.chosenModel = model.name; _ctx.showModelsList = false}
                        }, _toDisplayString(model.name), 9, _hoisted_15))
                      }), 128)),
                      (_ctx.modelsList.length == 0)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: "dropdown-item",
                            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showModelsList = !_ctx.showModelsList))
                          }, "No Models"))
                        : _createCommentVNode("", true)
                    ])), [
                      [_directive_click_outside, () => {
                _ctx.showModelsList = false
              }
                ]
                    ])
                  : _createCommentVNode("", true),
                _createElementVNode("div", {
                  class: "dropdown-item",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showModelsList = !_ctx.showModelsList))
                }, _toDisplayString(_ctx.chosenModel), 1),
                _createElementVNode("div", {
                  class: "dropdown-button",
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showModelsList = !_ctx.showModelsList))
                }, "arrow_drop_down")
              ])
            ]),
            _cache[39] || (_cache[39] = _createElementVNode("div", { style: {"height":"10px"} }, null, -1)),
            _createElementVNode("form", _hoisted_16, [
              _cache[36] || (_cache[36] = _createElementVNode("div", { class: "dropdown-label" }, "System Prompt:", -1)),
              _createElementVNode("div", _hoisted_17, [
                _withDirectives(_createElementVNode("textarea", {
                  class: "ai-chat-input w-input",
                  maxlength: "5000",
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.newSystemPrompt) = $event)),
                  onKeydown: _cache[8] || (_cache[8] = _withKeys(_withModifiers((e) => {
              if (e.shiftKey) {
                // add a newline character to the textarea content
                _ctx.newSystemPrompt += '\n';
              }
            }
              , ["prevent"]), ["enter"]))
                }, null, 544), [
                  [_vModelText, _ctx.newSystemPrompt]
                ])
              ])
            ]),
            _cache[40] || (_cache[40] = _createElementVNode("div", { style: {"height":"10px"} }, null, -1)),
            _createElementVNode("form", _hoisted_18, [
              _cache[37] || (_cache[37] = _createElementVNode("div", { class: "dropdown-label" }, "Temperature:", -1)),
              _createElementVNode("div", _hoisted_19, [
                _withDirectives(_createElementVNode("input", {
                  class: "chirp-search-input w-input",
                  placeholder: "Temperature",
                  type: "text",
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.ModelTemp) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.ModelTemp]
                ])
              ])
            ]),
            _cache[41] || (_cache[41] = _createElementVNode("div", { style: {"height":"10px"} }, null, -1)),
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("div", {
                class: "btn project-control full-width",
                onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.updateAiSettings && _ctx.updateAiSettings(...args)))
              }, " Update "),
              _createElementVNode("div", {
                class: "btn project-control full-width cancel",
                onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.showSettings = false))
              }, " Cancel ")
            ]),
            false
              ? (_openBlock(), _createElementBlock("div", _hoisted_21))
              : _createCommentVNode("", true),
            _cache[42] || (_cache[42] = _createElementVNode("br", null, null, -1))
          ]))
        : _createCommentVNode("", true)
    ]),
    (!_ctx.showSettings)
      ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("div", _hoisted_24, [
              (
            _ctx.showHistory)
                ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_25, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chatList, (chat, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "dropdown-item",
                        key: index,
                        onClick: ($event: any) => (_ctx.selectChat(chat)),
                        title: chat.title
                      }, _toDisplayString(chat.title), 9, _hoisted_26))
                    }), 128)),
                    (_ctx.chatList.length == 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_27, "No Chat History"))
                      : _createCommentVNode("", true)
                  ])), [
                    [_directive_click_outside, () => {
              _ctx.showHistory = false
            }
              ]
                  ])
                : _createCommentVNode("", true),
              (!_ctx.selectedChat)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "dropdown-item",
                    onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.showHistory = !_ctx.showHistory))
                  }, "Unsaved Chat"))
                : (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: "dropdown-item",
                    onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.showHistory = !_ctx.showHistory)),
                    title: _ctx.selectedChat.title
                  }, _toDisplayString(_ctx.selectedChat.title), 9, _hoisted_28)),
              _createElementVNode("div", {
                class: "dropdown-button",
                onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.showHistory = !_ctx.showHistory))
              }, "arrow_drop_down")
            ]),
            _createElementVNode("div", _hoisted_29, [
              (_ctx.selectedChat)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "btn project-control icon full-width red",
                    title: "Delete This Chat",
                    onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.isDeleting = true))
                  }, " delete"))
                : (_openBlock(), _createElementBlock("div", _hoisted_30, " delete ")),
              _createElementVNode("div", {
                class: "btn project-control icon full-width green",
                title: "Start a New Chat",
                onClick: _cache[16] || (_cache[16] = 
//@ts-ignore
(...args) => (_ctx.startNewChat && _ctx.startNewChat(...args)))
              }, "add ")
            ])
          ]),
          (_ctx.isDeleting)
            ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                _createElementVNode("div", {
                  class: "btn project-control full-width red",
                  onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.deleteChat()))
                }, "Delete Chat"),
                _createElementVNode("div", {
                  class: "btn project-control full-width cancel",
                  onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.isDeleting = false))
                }, "Cancel")
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.showSettings)
      ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
          _createElementVNode("div", _hoisted_33, [
            _createElementVNode("div", _hoisted_34, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (message, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "chirp-search-result-group",
                  key: index
                }, [
                  (message.role == 'user')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                        _createElementVNode("div", {
                          class: "ai-chat-block",
                          innerHTML: message.content.replace('@@css', `<span style='color: skyblue'>@@css</span>`)
                .replace('@@html', `<span style='color: skyblue'>@@html</span>`)
                .replace('@@js', `<span style='color: skyblue'>@@js</span>`)
                        }, null, 8, _hoisted_36)
                      ]))
                    : _createCommentVNode("", true),
                  (message.role == 'assistant')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                        _createVNode(_component_Markdown, {
                          class: "ai-chat-block ai",
                          content: message.content
                        }, null, 8, ["content"]),
                        _createElementVNode("div", {
                          class: "btn ai-chat-action",
                          onClick: ($event: any) => (_ctx.branchChat(index+1))
                        }, _cache[43] || (_cache[43] = [
                          _createElementVNode("span", { class: "icon-span" }, "account_tree", -1),
                          _createTextVNode("Branch")
                        ]), 8, _hoisted_38),
                        _cache[44] || (_cache[44] = _createElementVNode("div", { class: "ai-response-fader" }, null, -1))
                      ]))
                    : _createCommentVNode("", true)
                ]))
              }), 128)),
              (_ctx.aiThinking)
                ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                    _createVNode(_component_Markdown, {
                      class: "ai-chat-block ai",
                      content: _ctx.aiResponse
                    }, null, 8, ["content"]),
                    _cache[45] || (_cache[45] = _createElementVNode("div", { class: "ai-loading" }, null, -1))
                  ]))
                : _createCommentVNode("", true),
              (_ctx.messages.length > 0 && _ctx.messages[_ctx.messages.length - 1].role == 'assistant')
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "btn project-control full-width ai-regen",
                    onClick: _cache[19] || (_cache[19] = 
//@ts-ignore
(...args) => (_ctx.regenerateLastResponse && _ctx.regenerateLastResponse(...args)))
                  }, " Regenerate Last Response"))
                : _createCommentVNode("", true),
              (_ctx.messages.length > 2)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: "btn-ai-chat-go-to-bottom",
                    onClick: _cache[20] || (_cache[20] = 
//@ts-ignore
(...args) => (_ctx.scrollToBottom && _ctx.scrollToBottom(...args)))
                  }, " vertical_align_bottom "))
                : _createCommentVNode("", true)
            ], 512)
          ]),
          (_ctx.aiThinking)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "btn project-control full-width red",
                onClick: _cache[21] || (_cache[21] = ($event: any) => (_ctx.stopResponse = true))
              }, " Stop Response "))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: "ai-input-form w-form w-form",
            onSubmit: _cache[25] || (_cache[25] = (e) => {
        e.preventDefault();
        _ctx.messageAi(_ctx.aiPrompt);
        _ctx.aiPrompt = '';
        _ctx.scrollToBottom();
      }
        )
          }, [
            _createElementVNode("form", _hoisted_40, [
              _createElementVNode("div", _hoisted_41, [
                _withDirectives(_createElementVNode("textarea", {
                  class: "ai-chat-input w-input",
                  placeholder: "Chat with AI",
                  "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.aiPrompt) = $event)),
                  onKeydown: _cache[23] || (_cache[23] = _withKeys(_withModifiers((e) => {
                if (e.shiftKey) {
                  // add a newline character to the textarea content
                  _ctx.aiPrompt += '\n';
                } else {
                  _ctx.messageAi(_ctx.aiPrompt);
                  _ctx.aiPrompt = '';
                  _ctx.scrollToBottom();
                }
              }
                , ["prevent"]), ["enter"]))
                }, null, 544), [
                  [_vModelText, _ctx.aiPrompt]
                ]),
                _createElementVNode("div", {
                  class: "ai-chat-icon-wrapper",
                  onClick: _cache[24] || (_cache[24] = ($event: any) => {
              _ctx.messageAi(_ctx.aiPrompt);
            _ctx.aiPrompt = '';
            _ctx.scrollToBottom();
            })
                }, _cache[46] || (_cache[46] = [
                  _createElementVNode("div", { class: "normal-icon" }, "send", -1)
                ]))
              ])
            ])
          ], 32)
        ]))
      : _createCommentVNode("", true),
    (_ctx.modelsList.length == 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
          _cache[50] || (_cache[50] = _createElementVNode("div", { class: "chirp-page-title" }, "Local Ollama", -1)),
          _cache[51] || (_cache[51] = _createElementVNode("div", { class: "vertical-spacer" }, null, -1)),
          _cache[52] || (_cache[52] = _createElementVNode("div", { class: "api-key-instruction" }, [
            _createTextVNode(" Make sure you have "),
            _createElementVNode("a", {
              href: "https://ollama.com/",
              target: "_blank",
              class: "google-ai-link"
            }, "Ollama"),
            _createTextVNode(" installed and running ")
          ], -1)),
          _createElementVNode("div", null, [
            _cache[49] || (_cache[49] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("form", {
              class: "chirp-search-form-wrapper",
              style: {"grid-row-gap":"0"},
              onSubmit: _cache[27] || (_cache[27] = (e) => {
          e.preventDefault()
          _ctx.defaultHostUrl = _ctx.newHostUrl
          _ctx.initAi()
        })
            }, [
              _cache[48] || (_cache[48] = _createElementVNode("div", { class: "dropdown-label" }, "Ollama Host Url:", -1)),
              _createElementVNode("div", _hoisted_43, [
                _withDirectives(_createElementVNode("input", {
                  class: "chirp-search-input w-input",
                  placeholder: "Ollama Host Url",
                  type: "text",
                  "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.newHostUrl) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.newHostUrl]
                ]),
                _cache[47] || (_cache[47] = _createElementVNode("button", {
                  class: "btn save",
                  type: "submit"
                }, " Refresh ", -1))
              ])
            ], 32)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}