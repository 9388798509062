<template>
  <div class="chirp-body">
    <div class="chirp-panel project flex" v-if="chirp">
      <div class="side-menu" v-if="user && chirp.uid == user.uid">
        <div
          class="side-menu-item"
          :class="{ selected: menuTab == 'info' }"
          @click="menuTab = 'info'"
        >
          <div class="side-menu-item-icon">info</div>
        </div>
        <div
          class="side-menu-item"
          :class="{ selected: menuTab == 'AI' }"
          @click="menuTab = 'AI'"
        >
          <div class="side-menu-item-icon">neurology</div>
        </div>
      </div>
      <div class="left-panel" v-if="menuTab == 'AI'">
        <div
          class="ai-comp-title-row"
          @click="$router.back()"
          v-if="!editDesc && user && user.uid == chirp.uid"
        >
          <div class="btn project-control exit">close</div>
        </div>
        <AiChat relation="chirp"/>
      </div>
      <div class="chirp-page-left-side" v-if="menuTab == 'info'">
        <div
          class="ai-comp-title-row"
          @click="$router.back()"
          v-if="!editDesc && user && user.uid == chirp.uid"
        >
          <div class="btn project-control exit">close</div>
        </div>
        <div class="chirp-page-title-wrapper">
          <div class="chirp-page-title" v-if="!editName">{{ chirp.name }}</div>
          <div class="chirp-rename-action w-form" v-if="editName">
            <form class="tiny-action-form">
              <input
                class="input-100 w-input"
                :placeholder="chirp.name"
                type="text"
                v-model="newName"
              />
              <div class="input-action-wrapper">
                <div
                  class="normal-icon white input-action bigger green"
                  @click="
                    newName = newName.replace(/ /g, ' ').toLowerCase();
                    searchWords = newName.split(' ');
                    updateChirp('name', newName);
                    updateChirp('searchWords', searchWords);
                    editName = false;
                  "
                >
                  check
                </div>
                <div
                  class="normal-icon white input-action bigger red"
                  @click="
                    editName = false;
                    newName = '';
                  "
                >
                  close
                </div>
              </div>
            </form>
          </div>
          <div
            class="chirp-page-action-btn-wrapper"
            v-if="!editName && user && user.uid == chirp.uid"
            @click="editName = true"
          >
            <div class="normal-icon green">edit</div>
            <div class="tiny-input-label white">Rename</div>
          </div>
        </div>
        <div class="chirp-page-author">By {{ chirp.author }}</div>
        <div class="chirp-page-author">
          Craeted {{ new Date(chirp.createdDate).toLocaleDateString() }}
        </div>
        <div class="chirp-page-desc" v-if="!editDesc">
          Description:<br />{{ chirp.desc }}
        </div>
        <div class="chirp-edit-desc-form-wrapper w-form" v-if="editDesc">
          <form class="chirp-edit-desc-form">
            <textarea
              :placeholder="chirp.desc"
              maxlength="5000"
              class="input-100 chirp-desc w-input"
              v-model="newDesc"
            ></textarea>
            <div class="input-action-wrapper left-align">
              <div
                class="normal-icon white input-action bigger green"
                @click="
                  updateChirp('desc', newDesc);
                  editDesc = false;
                "
              >
                check
              </div>
              <div
                class="normal-icon white input-action bigger red"
                @click="
                  editDesc = false;
                  newDesc = '';
                "
              >
                close
              </div>
            </div>
          </form>
        </div>
        <div
          class="chirp-page-action-btn-wrapper"
          v-if="!editDesc && user && user.uid == chirp.uid"
          @click="editDesc = true"
        >
          <div class="normal-icon green">edit</div>
          <div class="tiny-input-label white">Edit Description</div>
        </div>
        <div
          class="chirp-page-publish-btn"
          @click="handlePublishChirp"
          v-if="!editDesc && user && user.uid == chirp.uid"
        >
          <div
            class="chirp-page-action-btn-wrapper blue"
            v-if="chirp.public === false"
          >
            <div class="normal-icon blue">cheer</div>
            <div class="tiny-input-label white">Publish to Public Library</div>
          </div>
          <div class="chirp-page-action-btn-wrapper red-border" v-else>
            <div class="normal-icon red">thunderstorm</div>
            <div class="tiny-input-label white">Unpublish</div>
          </div>
        </div>
      </div>
      <div class="chirp-page-right-side">
        <div class="chirp-code-editor">
          <VueMonacoEditor
            v-model:value="code"
            theme="vs-dark"
            :options="MONACO_EDITOR_OPTIONS"
            language="javascript"
            @mount="handleMount"
          />
        </div>
        <div class="project-footer">
          <div
            class="save-action-wrapper"
            v-if="!editDesc && user && user.uid == chirp.uid"
          >
            <div class="btn save" v-if="!saving" @click="saveCode">Save</div>
            <div class="btn save saving" v-if="saving">Save</div>
            <img
              src="https://cdn.prod.website-files.com/66b2a9b55aec6503eeabce5c/66e8457dc8ac9ca8ab5ec740_d158b93b-bded-4b54-ad27-8d22879a5fc2.svg"
              loading="lazy"
              alt=""
              class="save-animation saving"
              v-if="saving"
            />
            <div class="project-save-count saving" v-if="saving">Saving</div>
          </div>
        </div>
      </div>
    </div>
    <Notification :options="notificationOptions" v-if="showNotification" />
  </div>
</template>

<script lang="ts">
import { defineComponent, shallowRef } from "vue";
import { mapActions, mapMutations, mapState } from "vuex";
import {
  getDownloadURL,
  ref as storageRef,
} from "firebase/storage";
import { db, storage } from "@/firebase-app";
import { useRoute } from "vue-router";
import { VueMonacoEditor } from "@guolao/vue-monaco-editor";
import {
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  query,
  Query,
  QueryFieldFilterConstraint,
  updateDoc,
  where,
} from "firebase/firestore";
import Notification from "@/components/Notification.vue";
import AiChat from "@/components/AiChat.vue";

export default defineComponent({
  name: "ProjectsView",
  components: { Notification, AiChat },
  data() {
    return {
      menuTab: "info",
      newName: "",
      newDesc: "",
      searchWords: [] as string[],
      editName: false,
      editDesc: false,
      chirpDoc: null as any,
      chirp: null as any,
      chirpId: "" as string | string[],
      lodingChirp: false,
      code: `console.log('Just keep on chirping bud!')`,
      saving: false,
      editorRef: shallowRef(null),
      MONACO_EDITOR_OPTIONS: {
        automaticLayout: true,
        formatOnType: true,
        formatOnPaste: true,
        padding: "20px",
      },
      notificationOptions: null as any,
      showNotification: false,
    };
  },
  async mounted() {
    this.lodingChirp = true;
    // get the project details
    const route = useRoute();
    this.chirpId = route.params.id;

    if (this.chirpId) {
      console.log("Chirp id: ", this.chirpId);
      // Check if this chirp belongs to the logged in user
      if (this.user) {
        const targerChirp = doc(
          db,
          `users/${this.user.uid}/chirps`,
          this.chirpId as string
        );
        const docSnap = await getDoc(targerChirp);
        if (docSnap.exists()) {
          this.chirp = docSnap.data();
          this.setSelectedChirp(this.chirp)
          this.code = this.chirp.code;
          this.listenToChirp(this.chirpId as string);
        } else {
          console.log("No such document!");
        }
      } else {
        const targetChirpQuery = query(
          collectionGroup(db, "chirps"),
          where("id", "==", this.chirpId),
          where("public", "==", true),
          limit(1)
        );
        const querySnapshot = await getDocs(targetChirpQuery);
        if (querySnapshot.docs && querySnapshot.docs[0]?.data()) {
          this.chirp = querySnapshot.docs[0].data();
          this.code = this.chirp.code;
        }
      }
      document.addEventListener("keydown", this.handleSave);
    } else {
      console.log("no project id found");
    }
  },
  beforeUnmount() {
    document.removeEventListener("keydown", this.handleSave);
  },
  computed: {
    ...mapState("modLogin", ["user"]),
  },
  methods: {
    ...mapMutations("modChirp", ["setSelectedChirp"]),
    async updateChirp(field: string, value: any) {
      this.saving = true;
      const userDocRef = doc(
        db,
        `users/${this.user.uid}/chirps`,
        this.chirpId as string
      );
      try {
        await updateDoc(userDocRef, {
          [field]: value, // Use bracket notation to update a specific field
        });
        this.saving = false;
        console.log(`chirp ${field} updated.`);
      } catch (error) {
        console.error(`chirp ${field} not updated: `, error);
        this.saving = false;
      }
    },
    listenToChirp(chirpId: string) {
      if (this.chirpDoc) {
        this.chirpDoc();
      }
      const chirpRef = doc(db, `users/${this.chirp.uid}/chirps`, chirpId);
      this.chirpDoc = onSnapshot(chirpRef, (doc) => {
        this.chirp = doc.data();
        this.code = this.chirp.code;
      });
    },
    handlePublishChirp() {
      const currentPublishValue = this.chirp.public;
      const publish = {
        question: true, // question | confirm
        yesText: "Publish",
        noText: "Discard",
        title: "Publishing to Public Library",
        text: "You are about to publish this chirp to public library.",
        yesFunction: () => {
          this.setPublishChirp(true);
          this.showNotification = false;
        },
        noFunction: () => {
          this.showNotification = false;
        },
        isError: false,
      };
      const unpublish = {
        question: true, // question | confirm
        yesText: "Unpublish",
        noText: "Discard",
        title: "Unpublishing from Public Library",
        text: "You are about to unpublish this chirp from public library.",
        yesFunction: () => {
          this.setPublishChirp(false);
          this.showNotification = false;
        },
        noFunction: () => {
          this.showNotification = false;
        },
        isError: false,
      };
      if (currentPublishValue != true) {
        this.notificationOptions = publish;
      } else {
        this.notificationOptions = unpublish;
      }
      this.showNotification = true;
    },
    async setPublishChirp(value: boolean) {
      this.saving = true;
      const userDocRef = doc(
        db,
        `users/${this.user.uid}/chirps`,
        this.chirpId as string
      );
      try {
        await updateDoc(userDocRef, {
          ["public"]: value,
          published: new Date().getTime().toString(),
        });
        this.saving = false;
        console.log("Chirp public value is set");
      } catch (error) {
        console.error("Error changing chirp public value: ", error);
        this.saving = false;
      }
    },
    handleMount(editor: any) {
      this.editorRef = editor;
    },
    async saveCode() {
      this.saving = true;
      const userDocRef = doc(
        db,
        `users/${this.user.uid}/chirps`,
        this.chirpId as string
      );
      try {
        await updateDoc(userDocRef, {
          ["code"]: this.code, // Use bracket notation to update a specific field
        });
        this.saving = false;
        console.log("Field successfully updated!");
      } catch (error) {
        console.error("Error updating field: ", error);
        this.saving = false;
      }
    },
    handleSave(e: any) {
      if (e.ctrlKey && e.key === "s") {
        e.preventDefault();
        console.log("CTRL + S");
        this.saveCode();
      }
    },
  },
});
</script>
