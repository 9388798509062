<template>
  <div class="header">
    <div class="header-logo-div">
      <div class="header-logo" @click="setSelectedProjectId(null), $router.push('/')"></div>
      <div class="project-title" v-if="typeof selectedProjectId === 'string'" @click="console.log(selectedProject)">
        {{ selectedProjectId }}
      </div>
    </div>
    <div class="project-controls" v-if="$router.currentRoute.value.path.includes('/project')">
      <div class="project-control-button" :class="{ disabled: !htmlEditorView }" @click="toggleHtmlEditor">
        html
      </div>
      <div class="project-control-button" :class="{ disabled: !cssEditorView }" @click="toggleCssEditor">
        css
      </div>
      <div class="project-control-button" :class="{ disabled: !jsEditorView }" @click="toggleJsEditor">
        js
      </div>
    </div>
    <div class="project-controls" v-if="$router.currentRoute.value.path.includes('/preview')">
      <div class="project-control-button" @click="refreshPreview"><span class="icon">refresh</span>Refresh</div>
    </div>
    <div class="header-right-side-wrapper">
      
      <div class="header-user-wrapper" @click="openMenu = !openMenu">
        <div class="user-expand-btn">expand_more</div>
        <div class="user-letter-icon" v-if="user && userData">
          {{ userData.name }}
        </div>
        <div class="user-letter-icon" v-else>?</div>
      </div>
    </div>

    <div class="header-menu-bg" :class="{ open: openMenu }" @click="openMenu = !openMenu">
      <div class="header-menu">
        <div class="header-menu-item" v-if="user && userData">Account</div>
        <div class="header-menu-item">Plans</div>
        <div class="header-menu-item" v-if="user && userData">Billing</div>
        <div class="header-menu-item">Privacy Policy</div>
        <div class="header-menu-item">Terms &amp; Conditions</div>
        <div class="header-menu-item" v-if="user && userData" @click="signOut">
          Logout
        </div>
        <div class="header-menu-item" @click="$router.push('/')" v-if="!user">
          Login
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import store from "@/store";
import { defineComponent } from "vue";
import { mapActions, mapMutations, mapState } from "vuex";

export default defineComponent({
  name: "HeaderComp",
  data() {
    return {
      openMenu: false,
      //user: store.getters["modLogin/currentUser"],
    };
  },
  computed: {
    ...mapState("modLogin", ["user", "userData"]),
    ...mapState("modProjects", ["selectedProjectId", "selectedProject"]),
    ...mapState("modVersioning", ["selectedVersion"]),
    ...mapState(["htmlEditorView","cssEditorView","jsEditorView"]),
  },
  methods: {
    ...mapActions("modLogin", ["signOut"]),
    ...mapMutations("modProjects", [
      "setSelectedProjectId",
      "setShowScriptTags",
    ]),
    ...mapMutations(["refreshPreview", "toggleJsEditor", "toggleHtmlEditor", "toggleCssEditor"])
  },
});
</script>
