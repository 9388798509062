import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "chirp-body" }
const _hoisted_2 = {
  key: 0,
  class: "chirp-panel project flex"
}
const _hoisted_3 = {
  key: 0,
  class: "side-menu"
}
const _hoisted_4 = {
  key: 1,
  class: "left-panel"
}
const _hoisted_5 = {
  key: 2,
  class: "chirp-page-left-side"
}
const _hoisted_6 = { class: "chirp-page-title-wrapper" }
const _hoisted_7 = {
  key: 0,
  class: "chirp-page-title"
}
const _hoisted_8 = {
  key: 1,
  class: "chirp-rename-action w-form"
}
const _hoisted_9 = { class: "tiny-action-form" }
const _hoisted_10 = ["placeholder"]
const _hoisted_11 = { class: "input-action-wrapper" }
const _hoisted_12 = { class: "chirp-page-author" }
const _hoisted_13 = { class: "chirp-page-author" }
const _hoisted_14 = {
  key: 1,
  class: "chirp-page-desc"
}
const _hoisted_15 = {
  key: 2,
  class: "chirp-edit-desc-form-wrapper w-form"
}
const _hoisted_16 = { class: "chirp-edit-desc-form" }
const _hoisted_17 = ["placeholder"]
const _hoisted_18 = { class: "input-action-wrapper left-align" }
const _hoisted_19 = {
  key: 0,
  class: "chirp-page-action-btn-wrapper blue"
}
const _hoisted_20 = {
  key: 1,
  class: "chirp-page-action-btn-wrapper red-border"
}
const _hoisted_21 = { class: "chirp-page-right-side" }
const _hoisted_22 = { class: "chirp-code-editor" }
const _hoisted_23 = { class: "project-footer" }
const _hoisted_24 = {
  key: 0,
  class: "save-action-wrapper"
}
const _hoisted_25 = {
  key: 1,
  class: "btn save saving"
}
const _hoisted_26 = {
  key: 2,
  src: "https://cdn.prod.website-files.com/66b2a9b55aec6503eeabce5c/66e8457dc8ac9ca8ab5ec740_d158b93b-bded-4b54-ad27-8d22879a5fc2.svg",
  loading: "lazy",
  alt: "",
  class: "save-animation saving"
}
const _hoisted_27 = {
  key: 3,
  class: "project-save-count saving"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AiChat = _resolveComponent("AiChat")!
  const _component_VueMonacoEditor = _resolveComponent("VueMonacoEditor")!
  const _component_Notification = _resolveComponent("Notification")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.chirp)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.user && _ctx.chirp.uid == _ctx.user.uid)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", {
                  class: _normalizeClass(["side-menu-item", { selected: _ctx.menuTab == 'info' }]),
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.menuTab = 'info'))
                }, _cache[15] || (_cache[15] = [
                  _createElementVNode("div", { class: "side-menu-item-icon" }, "info", -1)
                ]), 2),
                _createElementVNode("div", {
                  class: _normalizeClass(["side-menu-item", { selected: _ctx.menuTab == 'AI' }]),
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.menuTab = 'AI'))
                }, _cache[16] || (_cache[16] = [
                  _createElementVNode("div", { class: "side-menu-item-icon" }, "neurology", -1)
                ]), 2)
              ]))
            : _createCommentVNode("", true),
          (_ctx.menuTab == 'AI')
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (!_ctx.editDesc && _ctx.user && _ctx.user.uid == _ctx.chirp.uid)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "ai-comp-title-row",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.back()))
                    }, _cache[17] || (_cache[17] = [
                      _createElementVNode("div", { class: "btn project-control exit" }, "close", -1)
                    ])))
                  : _createCommentVNode("", true),
                _createVNode(_component_AiChat, { relation: "chirp" })
              ]))
            : _createCommentVNode("", true),
          (_ctx.menuTab == 'info')
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (!_ctx.editDesc && _ctx.user && _ctx.user.uid == _ctx.chirp.uid)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "ai-comp-title-row",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.back()))
                    }, _cache[18] || (_cache[18] = [
                      _createElementVNode("div", { class: "btn project-control exit" }, "close", -1)
                    ])))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_6, [
                  (!_ctx.editName)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.chirp.name), 1))
                    : _createCommentVNode("", true),
                  (_ctx.editName)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _createElementVNode("form", _hoisted_9, [
                          _withDirectives(_createElementVNode("input", {
                            class: "input-100 w-input",
                            placeholder: _ctx.chirp.name,
                            type: "text",
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.newName) = $event))
                          }, null, 8, _hoisted_10), [
                            [_vModelText, _ctx.newName]
                          ]),
                          _createElementVNode("div", _hoisted_11, [
                            _createElementVNode("div", {
                              class: "normal-icon white input-action bigger green",
                              onClick: _cache[5] || (_cache[5] = ($event: any) => {
                    _ctx.newName = _ctx.newName.replace(/ /g, ' ').toLowerCase();
                    _ctx.searchWords = _ctx.newName.split(' ');
                    _ctx.updateChirp('name', _ctx.newName);
                    _ctx.updateChirp('searchWords', _ctx.searchWords);
                    _ctx.editName = false;
                  })
                            }, " check "),
                            _createElementVNode("div", {
                              class: "normal-icon white input-action bigger red",
                              onClick: _cache[6] || (_cache[6] = ($event: any) => {
                    _ctx.editName = false;
                    _ctx.newName = '';
                  })
                            }, " close ")
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (!_ctx.editName && _ctx.user && _ctx.user.uid == _ctx.chirp.uid)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 2,
                        class: "chirp-page-action-btn-wrapper",
                        onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.editName = true))
                      }, _cache[19] || (_cache[19] = [
                        _createElementVNode("div", { class: "normal-icon green" }, "edit", -1),
                        _createElementVNode("div", { class: "tiny-input-label white" }, "Rename", -1)
                      ])))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_12, "By " + _toDisplayString(_ctx.chirp.author), 1),
                _createElementVNode("div", _hoisted_13, " Craeted " + _toDisplayString(new Date(_ctx.chirp.createdDate).toLocaleDateString()), 1),
                (!_ctx.editDesc)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      _cache[20] || (_cache[20] = _createTextVNode(" Description:")),
                      _cache[21] || (_cache[21] = _createElementVNode("br", null, null, -1)),
                      _createTextVNode(_toDisplayString(_ctx.chirp.desc), 1)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.editDesc)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                      _createElementVNode("form", _hoisted_16, [
                        _withDirectives(_createElementVNode("textarea", {
                          placeholder: _ctx.chirp.desc,
                          maxlength: "5000",
                          class: "input-100 chirp-desc w-input",
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.newDesc) = $event))
                        }, null, 8, _hoisted_17), [
                          [_vModelText, _ctx.newDesc]
                        ]),
                        _createElementVNode("div", _hoisted_18, [
                          _createElementVNode("div", {
                            class: "normal-icon white input-action bigger green",
                            onClick: _cache[9] || (_cache[9] = ($event: any) => {
                  _ctx.updateChirp('desc', _ctx.newDesc);
                  _ctx.editDesc = false;
                })
                          }, " check "),
                          _createElementVNode("div", {
                            class: "normal-icon white input-action bigger red",
                            onClick: _cache[10] || (_cache[10] = ($event: any) => {
                  _ctx.editDesc = false;
                  _ctx.newDesc = '';
                })
                          }, " close ")
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (!_ctx.editDesc && _ctx.user && _ctx.user.uid == _ctx.chirp.uid)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 3,
                      class: "chirp-page-action-btn-wrapper",
                      onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.editDesc = true))
                    }, _cache[22] || (_cache[22] = [
                      _createElementVNode("div", { class: "normal-icon green" }, "edit", -1),
                      _createElementVNode("div", { class: "tiny-input-label white" }, "Edit Description", -1)
                    ])))
                  : _createCommentVNode("", true),
                (!_ctx.editDesc && _ctx.user && _ctx.user.uid == _ctx.chirp.uid)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 4,
                      class: "chirp-page-publish-btn",
                      onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.handlePublishChirp && _ctx.handlePublishChirp(...args)))
                    }, [
                      (_ctx.chirp.public === false)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_19, _cache[23] || (_cache[23] = [
                            _createElementVNode("div", { class: "normal-icon blue" }, "cheer", -1),
                            _createElementVNode("div", { class: "tiny-input-label white" }, "Publish to Public Library", -1)
                          ])))
                        : (_openBlock(), _createElementBlock("div", _hoisted_20, _cache[24] || (_cache[24] = [
                            _createElementVNode("div", { class: "normal-icon red" }, "thunderstorm", -1),
                            _createElementVNode("div", { class: "tiny-input-label white" }, "Unpublish", -1)
                          ])))
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", _hoisted_22, [
              _createVNode(_component_VueMonacoEditor, {
                value: _ctx.code,
                "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.code) = $event)),
                theme: "vs-dark",
                options: _ctx.MONACO_EDITOR_OPTIONS,
                language: "javascript",
                onMount: _ctx.handleMount
              }, null, 8, ["value", "options", "onMount"])
            ]),
            _createElementVNode("div", _hoisted_23, [
              (!_ctx.editDesc && _ctx.user && _ctx.user.uid == _ctx.chirp.uid)
                ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                    (!_ctx.saving)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: "btn save",
                          onClick: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.saveCode && _ctx.saveCode(...args)))
                        }, "Save"))
                      : _createCommentVNode("", true),
                    (_ctx.saving)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_25, "Save"))
                      : _createCommentVNode("", true),
                    (_ctx.saving)
                      ? (_openBlock(), _createElementBlock("img", _hoisted_26))
                      : _createCommentVNode("", true),
                    (_ctx.saving)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_27, "Saving"))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.showNotification)
      ? (_openBlock(), _createBlock(_component_Notification, {
          key: 1,
          options: _ctx.notificationOptions
        }, null, 8, ["options"]))
      : _createCommentVNode("", true)
  ]))
}