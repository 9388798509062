<template>
  <div class="new-chirp-div">
    <div class="new-chirp-box">
      <div class="new-chirp-box-title">New Chirp</div>
      <div class="new-chirp-input w-form">
        <form
          class="new-chirp-form-wrapper"
          @submit="
            (e) => {
              e.preventDefault();
              createNewChirp({
                newChirpName: newChirpName,
                newChirpDesc: newChirpDesc,
              }).then((result) => {
                console.log('result', result);
                if (result.success === true) {
                  $router.push(`/chirp/${result.id}`);
                  setNewChirpDialog(false);
                }
              });
            }
          "
        >
          <div class="form-input-wrapper">
            <label for="email-3" class="form-label">Chirp Name</label
            ><input class="form-input w-input" v-model="newChirpName" />
          </div>
          <div class="form-input-wrapper">
            <label for="email-3" class="form-label">Chirp Description</label
            ><textarea
              class="form-input textarea w-input"
              v-model="newChirpDesc"
            ></textarea>
          </div>
          <div class="btns-wrapper">
            <div
              class="notification-btn"
              @click="
                createNewChirp({
                  newChirpName: newChirpName,
                  newChirpDesc: newChirpDesc,
                }).then((result) => {
                  console.log('result', result);
                  if (result.success === true) {
                    $router.push(`/chirp/${result.id}`);
                    setNewChirpDialog(false);
                  }
                })
              "
            >
              Create
            </div>
            <div
              class="notification-btn cancel"
              @click="setNewChirpDialog(false)"
            >
              Cancel
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="chirp-library-preview hidden">
      <div class="chirp-preview-box">
        <div class="chirp-preview-info">
          <div class="chirp-preview-name">GSAP Starter Chirp</div>
          <div class="chirp-preview-by">By ChirpJS</div>
          <div class="chirp-preview-desc">
            Description:<br />A starter snippet for GSAP project. Created by
            ChirpJS and made available to public.
          </div>
        </div>
        <div class="chirp-preview-right">
          <div class="chirp-preview-code-wrapper">
            <div class="chirp-preview-code">
              Description:A starter snippet for GSAP pr<br />oject. Created by
              ChirpJS and made<br />
              available to p<br />ublic.Description:A starter snippet for GSAP
              project. Created by ChirpJS and made a<br />vailable to
              public.Description:A starter snippet for GS<br />AP project.
              Created by ChirpJS and made available to public.Description:A s<br />tarter
              snipp<br />et for GSAP project. Created by ChirpJS and made<br />
              available to public.Description:A starter snippet for GSAP
              project. Created by Chirp<br />JS and made available to
              public.Descri<br />ption:A starter snippet for GSAP proj<br />ect.
              Created by ChirpJS an<br />d made available to
              public.Description:A starter snippet for GSAP proj<br />ect.
              Crea<br />ted by ChirpJS and made available t<br />o
              public.Description:A starter snippet for GSAP project. Created by
              ChirpJS and made a<br />vailable to public.Description:A starter
              snippet for GSAP p<br />roject. Created by ChirpJS and made
              available to pu<br />blic.Description:A starter<br />
              snippet for GSAP project. C<br />reated by ChirpJS and m<br />ade
              available to public.Description:<br />A starter snippet for<br />
              GSAP project. Created<br />
              by ChirpJS and made availab<br />le to public.D<br />escription:A
              star<br />ter snippet for GSAP project. Created by ChirpJS and
              ma<br />de available to public.Description:A starter snippet for
              <br />GSAP project. Cre<br />ated by ChirpJS and m<br />ade
              available to public.Description:A starter <br />snippet for GSAP
              project. Created by ChirpJS an<br />d made available to public.<br />Description:A
              starter snippet for GSAP project. Created by C<br />hirpJS and
              made avail<br />able to pub<br />lic.Description:A starter snippet
              for GSAP project. Created by ChirpJS <br />and made avar GSAP
              <br />project. C
            </div>
            <div class="chirp-preview-btns-wrapper">
              <div class="notification-btn blue">Clone</div>
              <div class="notification-btn cancel">Cancel</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapActions, mapMutations, mapState } from "vuex";

export default defineComponent({
  name: "NewChirpDialog",
  data() {
    return {
      tab: "new",
      newChirpName: "",
      newChirpDesc: "",
    };
  },
  props: {
    project: {} as any,
  },
  mounted() {
    this.getPublicChirps();
  },
  computed: {
    ...mapState("modChirp", ["publicChirps"]),
  },
  methods: {
    ...mapMutations("modChirp", ["setNewChirpDialog"]),
    ...mapActions("modChirp", ["createNewChirp", "getPublicChirps"]),
  },
});
</script>
