import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "body" }
const _hoisted_2 = {
  key: 0,
  class: "workspace-wrapper"
}
const _hoisted_3 = {
  class: "left-panel workspace",
  style: {"width":"300px","padding-right":"40px"}
}
const _hoisted_4 = { class: "worspace-name-wrapper" }
const _hoisted_5 = { class: "workspace-name" }
const _hoisted_6 = { class: "left-panel-list workspace" }
const _hoisted_7 = { class: "right-panel" }
const _hoisted_8 = {
  key: 0,
  class: "workspace-items-container"
}
const _hoisted_9 = {
  key: 0,
  class: "workspace-items-container"
}
const _hoisted_10 = { class: "empty-projects-btn-wrapper" }
const _hoisted_11 = {
  key: 1,
  class: "workspace-items-container"
}
const _hoisted_12 = { class: "new-project-form w-form" }
const _hoisted_13 = { class: "form-input-wrapper" }
const _hoisted_14 = { class: "btns-wrapper" }
const _hoisted_15 = {
  key: 0,
  class: "error-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProjectCard = _resolveComponent("ProjectCard")!
  const _component_ChirpCard = _resolveComponent("ChirpCard")!
  const _component_new_chirp_dialog = _resolveComponent("new-chirp-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.userData)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _cache[10] || (_cache[10] = _createElementVNode("div", { class: "normal-icon" }, "dashboard", -1)),
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.userData.name) + "'s Workspace", 1)
            ]),
            _createElementVNode("div", {
              class: "btn big",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showNewProject = true))
            }, " Start a new project "),
            _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", {
                class: _normalizeClass(["left-panel-list-item", { selected: _ctx.workspaceNav == 'projects' }]),
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setWorkspaceNav('projects')))
              }, " Projects (" + _toDisplayString(_ctx.userProjects.length) + ") ", 3),
              _cache[11] || (_cache[11] = _createElementVNode("div", { class: "left-panel-list-divider" }, null, -1)),
              _createElementVNode("div", {
                class: _normalizeClass(["left-panel-list-item", { selected: _ctx.workspaceNav == 'chirps' }]),
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setWorkspaceNav('chirps')))
              }, " Chirps (" + _toDisplayString(_ctx.chirps.length) + ") ", 3),
              _cache[12] || (_cache[12] = _createElementVNode("div", { class: "left-panel-list-divider" }, null, -1)),
              _createElementVNode("div", {
                class: _normalizeClass(["left-panel-list-item", { selected: _ctx.workspaceNav == 'tutorials' }]),
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setWorkspaceNav('tutorials')))
              }, " Tutorials ", 2)
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[18] || (_cache[18] = _createElementVNode("div", { class: "workspace-search-container hidden" }, [
              _createElementVNode("div", { class: "workspace-search-form-wrapper w-form" }, [
                _createElementVNode("form", {
                  id: "email-form",
                  name: "email-form",
                  "data-name": "Email Form",
                  method: "get",
                  class: "workspace-search-form",
                  "data-wf-page-id": "66b2ac9bfe9ffcb604182671",
                  "data-wf-element-id": "8c83358b-e6e6-3b7f-f56f-abaefb9f47ab",
                  "aria-label": "Email Form"
                }, [
                  _createElementVNode("input", {
                    class: "workspace-search-input w-input",
                    maxlength: "256",
                    name: "name",
                    "data-name": "Name",
                    placeholder: "Search Projects & Tutorials",
                    type: "text",
                    id: "name"
                  }),
                  _createElementVNode("div", { class: "workspace-search-input-btn" }, "search")
                ]),
                _createElementVNode("div", {
                  class: "w-form-done",
                  tabindex: "-1",
                  role: "region",
                  "aria-label": "Email Form success"
                }, [
                  _createElementVNode("div", null, "Thank you! Your submission has been received!")
                ]),
                _createElementVNode("div", {
                  class: "w-form-fail",
                  tabindex: "-1",
                  role: "region",
                  "aria-label": "Email Form failure"
                }, [
                  _createElementVNode("div", null, "Oops! Something went wrong while submitting the form.")
                ])
              ])
            ], -1)),
            (_ctx.workspaceNav == 'projects')
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _cache[15] || (_cache[15] = _createElementVNode("div", { class: "right-panel-title" }, "Projects", -1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userProjects, (project) => {
                    return (_openBlock(), _createBlock(_component_ProjectCard, {
                      key: project.name,
                      project: project
                    }, null, 8, ["project"]))
                  }), 128)),
                  (_ctx.userProjects.length == 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _cache[14] || (_cache[14] = _createElementVNode("div", { class: "empty-project-list" }, "No Projects Yet", -1)),
                        _createElementVNode("div", _hoisted_10, [
                          _createElementVNode("div", {
                            class: "btn big limit-width",
                            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showNewProject = true))
                          }, " Start a new project ")
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (_ctx.workspaceNav == 'chirps')
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _cache[17] || (_cache[17] = _createElementVNode("div", { class: "right-panel-title" }, "Chirps", -1)),
                  _createElementVNode("div", {
                    class: "workspace-item-wrapper new-chirp",
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.setNewChirpDialog(true)))
                  }, _cache[16] || (_cache[16] = [
                    _createStaticVNode("<div class=\"item-icon active\">add_circle</div><div class=\"item-detail-wrapper\"><div class=\"item-details center\">Create a new chirp</div></div><div class=\"item-options hidden\"><div class=\"item-option\">Rename</div><div class=\"item-option\">Unpublish</div><div class=\"item-option red\">Delete</div><div class=\"item-option-close-btn\">close</div></div>", 3)
                  ])),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chirps, (chirp) => {
                    return (_openBlock(), _createBlock(_component_ChirpCard, {
                      key: chirp.name,
                      chirp: chirp
                    }, null, 8, ["chirp"]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["new-project-div", { open: _ctx.showNewProject }])
    }, [
      _cache[20] || (_cache[20] = _createElementVNode("div", { class: "new-project-form-title" }, [
        _createElementVNode("span", { class: "new-project-icon" }, "family_star"),
        _createTextVNode(" New ChirpJS Project ")
      ], -1)),
      _cache[21] || (_cache[21] = _createElementVNode("br", null, null, -1)),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("form", {
          name: "project-form",
          onSubmit: _cache[7] || (_cache[7] = (e) => {
          e.preventDefault();
          _ctx.createProject();
        })
        }, [
          _createElementVNode("div", _hoisted_13, [
            _cache[19] || (_cache[19] = _createElementVNode("label", {
              for: "project-title",
              class: "form-label"
            }, "Project Name", -1)),
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.newProjectName) = $event)),
              class: "form-input w-input",
              maxlength: "256",
              name: "project-title",
              placeholder: "",
              type: "text"
            }, null, 512), [
              [_vModelText, _ctx.newProjectName]
            ])
          ])
        ], 32)
      ]),
      _cache[22] || (_cache[22] = _createElementVNode("br", null, null, -1)),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", {
          class: "notification-btn",
          onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.createProject && _ctx.createProject(...args)))
        }, "Create"),
        _createElementVNode("div", {
          class: "notification-btn red",
          onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.showNewProject = false))
        }, " Discard ")
      ]),
      (_ctx.errorMessage.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(_ctx.errorMessage), 1))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.newChirpDialog)
      ? (_openBlock(), _createBlock(_component_new_chirp_dialog, { key: 1 }))
      : _createCommentVNode("", true)
  ]))
}