import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { install as VueMonacoEditorPlugin } from "@guolao/vue-monaco-editor";
import vClickOutside from "click-outside-vue3"

/**
console.log = () => {
  //
};
console.error = () => {
  //
};
console.warn = () => {
  //
};
*/

createApp(App)
  .use(VueMonacoEditorPlugin, {
    paths: {
      // The recommended CDN config
      vs: "https://cdn.jsdelivr.net/npm/monaco-editor@0.43.0/min/vs",
    },
  })
  .use(store)
  .use(router)
  .use(vClickOutside)
  .mount("#app");
