import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "notifiction-div"
}
const _hoisted_2 = { class: "notification-box" }
const _hoisted_3 = { class: "notification-text" }
const _hoisted_4 = { class: "btns-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.options)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: _normalizeClass(["notification-title", { red: _ctx.options.isError }])
          }, _toDisplayString(_ctx.options.title), 3),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.options.text), 1),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", {
              class: "notification-btn",
              onClick: _cache[0] || (_cache[0] = ($event: any) => {
            if (_ctx.options.yesFunction != (null || undefined)) {
              _ctx.options.yesFunction();
            } else {
              _ctx.setShowNotification(false);
            }
          })
            }, _toDisplayString(_ctx.options.yesText), 1),
            (_ctx.options.question === true)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "notification-btn red",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => {
            if (_ctx.options.noFunction != (null || undefined)) {
              _ctx.options.noFunction();
            } else {
              _ctx.setShowNotification(false);
            }
          })
                }, _toDisplayString(_ctx.options.noText), 1))
              : _createCommentVNode("", true)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}