import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["srcdoc"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "header-spacer" }, null, -1)),
    (_ctx.selectedProject)
      ? (_openBlock(), _createElementBlock("iframe", {
          key: 0,
          class: "preview-iframe",
          srcdoc: _ctx.content
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true)
  ], 64))
}