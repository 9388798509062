import { db } from "../firebase-app";
import { Module } from "vuex";
import {
  addDoc,
  collection,
  doc,
  DocumentData,
  DocumentSnapshot,
  getCountFromServer,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { version } from "vue";

const modVersioning: Module<any, any> = {
  namespaced: true,
  state: {
    versions: [] as any[],
    selectedVersion: null as any,
    jsCode: "",
    htmlCode: "",
    cssCode: "",
    versionSnapshot: null as unknown as DocumentSnapshot,
  },
  mutations: {
    setJsCode(state, value) {
      state.jsCode = value
    },
    setHtmlCode(state, value) {
      state.htmlCode = value
    },
    setCssCode(state, value) {
      state.cssCode = value
    },
    setVersions(state, versions) {
      state.versions = versions;
    },
    setSelectedVersion(state, version) {
      state.selectedVersion = version;
      console.log("Current Version: ", version)
    },
    setVersionSnapshot(state, snapshot) {
      state.versionSnapshot = snapshot
    }
  },
  actions: {
    getVersions({ commit, rootGetters }, projectId) {
      const user = rootGetters["modLogin/currentUser"];
      const versionsCollection = collection(
        db,
        `users/${user.uid}/projects/${projectId}/versions`
      );
      const q = query(
        versionsCollection,
        orderBy("created", "desc"),
        where("deleted", "==", false)
      );
      onSnapshot(q, (querySnapshot) => {
        const versions: DocumentData[] = [];
        querySnapshot.forEach((doc) => {
          console.log(doc.data());
          const version = {
            id: doc.id,
            ...doc.data(),
          };
          versions.push(version);
        });
        console.log("Versions: ", versions);
        commit("setVersions", versions);
      });
    },
    async getVersion({ commit, rootGetters, state }, { projectId, versionId }) {
      if (state.versionSnapshot) {
        state.versionSnapshot();
      }
      const user = rootGetters["modLogin/currentUser"];
      const versionDoc = doc(db, `users/${user.uid}/projects/${projectId}/versions/${versionId}`)
      commit("setVersionSnapshot",
        onSnapshot(versionDoc, (versionDoc) => {
          const version: any = {
            id: versionDoc.id,
            ...versionDoc.data()
          }
          commit("setSelectedVersion", version);
          commit("setJsCode", version.jsCode);
          commit("setHtmlCode", version.htmlCode);
          commit("setCssCode", version.cssCode);
        }
        )
      )


    },
    async createNewVersion({ rootGetters }, { code, comments, projectId }) {
      const user = rootGetters["modLogin/currentUser"];
      // get version count from server
      const q = query(
        collection(db, `users/${user.uid}/projects/${projectId}/versions`)
      );
      const snap = await getCountFromServer(q);
      const versionNumber = (snap.data().count * 0.1).toFixed(1);

      const version = {
        number: versionNumber,
        comments: comments,
        jsCode: code.jsCode,
        htmlCode: code.htmlCode,
        cssCode: code.cssCode,
        created: new Date().getTime(),
        deleted: false,
      };
      const versionsRef = collection(
        db,
        `users/${user.uid}/projects/${projectId}/versions`
      );
      addDoc(versionsRef, version)
        .then(async (versionDoc) => {
          console.log(`Added a new version: ${versionNumber}`, doc);
          // update the selected version in the project document
          const projectDoc = doc(
            db,
            `users/${user.uid}/projects/${projectId}`
          );
          await setDoc(projectDoc, {
            selectedVersion: versionDoc.id
          }, { merge: true })
        })
        .catch((error) => {
          console.error("Error adding a new version: ", error);
        });
    },
    async updateVersionComments(
      { rootGetters },
      { projectId, versionId, comments }
    ) {
      const user = rootGetters["modLogin/currentUser"];
      const versionDoc = doc(
        db,
        `users/${user.uid}/projects/${projectId}/versions/${versionId}`
      );
      await setDoc(versionDoc, {
        ["comments"]: comments,
      })
        .then(() => {
          console.log("version comments updated");
        })
        .catch((error) => {
          console.error("error updating version comments", error);
        });
    },

    async markVersion(
      { rootGetters },
      { projectId, versionId, env }
    ) {
      let change = {} as any
      if (env == 'prod') {
        change.prodVersion = versionId
      } else if (env == 'dev') {
        change.devVersion = versionId
      }
      const user = rootGetters["modLogin/currentUser"];
      const projectDoc = doc(
        db,
        `users/${user.uid}/projects/${projectId}`
      );



      if (env == 'prod') {
        await setDoc(projectDoc, {
          prodVersion: versionId
        }, { merge: true })
          .then(() => {
            console.log("version marked in project file");
          })
          .catch((error) => {
            console.error("error updating version", error);
          });
      } else if (env == 'dev') {
        await setDoc(projectDoc, {
          devVersion: versionId
        }, { merge: true })
          .then(() => {
            console.log("version marked in project file");
          })
          .catch((error) => {
            console.error("error updating version", error);
          });
      }

    },
    async deleteVersion(
      { rootGetters, commit },
      { versionId, projectId }
    ): Promise<boolean> {
      let isDeleted = false;
      const user = rootGetters["modLogin/currentUser"];
      const targetVersion = doc(
        db,
        `users/${user.uid}/projects/${projectId}/versions/`,
        versionId
      );
      await setDoc(targetVersion, {
        ["deleted"]: true,
      })
        .then(() => {
          isDeleted = true;
          commit("setSelectedVersion", null)
          console.log(`version ${versionId} deleted`, isDeleted);
        })
        .catch((error) => {
          console.error("Error deleting version", error);
        });
      return isDeleted;
    },
  },
};
export default modVersioning;
