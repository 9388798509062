import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "left-panel-list project" }
const _hoisted_2 = { class: "preview-tab-title-wrapper" }
const _hoisted_3 = { class: "comp-header-icon-wrapper" }
const _hoisted_4 = ["srcdoc"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "doc-status-wrapper" }, [
        _createElementVNode("div", { class: "doc-stateus-text" }, "Preview")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "btn project-control doc-save icon",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openRouteInNewTab(`/preview/${_ctx.selectedProject.id}`)))
        }, " open_in_new"),
        _createElementVNode("div", {
          class: "btn project-control doc-save icon",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updatePreview()))
        }, "refresh")
      ])
    ]),
    (_ctx.showPreview)
      ? (_openBlock(), _createElementBlock("iframe", {
          key: 0,
          style: {"background-color":"white","width":"100%","height":"100%","color":"black"},
          srcdoc: _ctx.renderedHtmlCode
        }, null, 8, _hoisted_4))
      : _createCommentVNode("", true)
  ]))
}