<template>
  <body class="body">
    <div class="big-header">ChirpJS Login</div>
    <div class="form-block w-form">
      <form class="form">
        <div class="login-form-inputs-wrapper">
          <label for="email-2" class="input-label">E-Mail</label
          ><input
            v-model="emailInput"
            class="input-text w-input"
            autofocus="true"
            maxlength="256"
            type="email"
          />
          <br />
          <label for="password" class="input-label">Password</label>
          <input
            v-model="passwordInput"
            class="input-text w-input"
            maxlength="256"
            placeholder=""
            type="password"
          />
        </div>
        <div
          class="btn w-button"
          @click="
            signInWithEmail({ email: emailInput, password: passwordInput })
          "
        >
          Login
        </div>
        <div class="text-center">Or</div>
        <div class="btn google w-button" @click="signinWithGoogle">
          Login With Google
        </div>
        <div class="register-text">Don't have an account?</div>
        <div
          type="submit"
          class="btn register w-button"
          @click="$router.push('/register')"
        >
          Register (Quick &amp; Free)
        </div>
      </form>
    </div>
  </body>
</template>

<script lang="ts">
import router from "@/router";
import { defineComponent } from "vue";

import { mapActions, mapState } from "vuex";
export default defineComponent({
  name: "HomeView",
  components: {},
  data() {
    return {
      emailInput: "",
      passwordInput: "",
    };
  },
  setup() {
    //
  },
  async mounted() {
    if (this.user || this.userData) {
      router.push("/workspace");
    }
  },
  computed: {
    ...mapState("modLogin", ["user", "userData"]),
  },
  watch: {
    userData(newVal, oldVal) {
      console.log("deteted change in user Data", newVal);
      if (newVal && newVal !== oldVal && !newVal.nameChanged) {
        router.push("/name");
      } else if (newVal && newVal !== oldVal && newVal.nameChanged) {
        router.push("/workspace");
      }
    },
  },
  methods: {
    ...mapActions("modLogin", [
      "signinWithGoogle",
      "signInWithEmail",
      "getUserData",
    ]),
  },
});
</script>
