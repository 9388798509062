import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import WorkspaceView from "../views/WorkspaceView.vue";
import ProjectView from "../views/ProjectView.vue";
import NameView from "@/views/NameView.vue";
import RegisterView from "@/views/RegisterView.vue";
import ChirpView from "@/views/ChirpView.vue";
import PreviewView from "@/views/PreviewView.vue";
import store from "@/store";
import { doc, getDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "@/firebase-app";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/name",
    name: "name",
    component: NameView,
    meta: { requiresAuth: true },
  },
  {
    path: "/register",
    name: "register",
    component: RegisterView,
  },
  {
    path: "/workspace",
    name: "workspace",
    component: WorkspaceView,
    meta: { requiresAuth: true },
  },
  {
    path: "/project/:id",
    name: "ProjectView",
    component: ProjectView,
    meta: { requiresAuth: true },
  },
  {
    path: "/preview/:id",
    name: "PreviewView",
    component: PreviewView,
    meta: { requiresAuth: true },
  },
  {
    path: "/chirp/:id",
    name: "ChirpView",
    component: ChirpView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

let authChecked = false;

const checkAuthState = async () => {
  return new Promise<void>((resolve) => {
    if (authChecked) {
      resolve();
      return;
    }

    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in
        store.commit("modLogin/setUser", user);
        try {
          store.dispatch("modLogin/getUserData", user.uid);
          const userDocRef = doc(db, `users/${user.uid}`);
          const userDocResult = await getDoc(userDocRef);
          if (userDocResult.exists()) {
            store.commit("modLogin/setUserData", userDocResult.data());
          } else {
            console.log("user not found,");
          }
        } catch (error) {
          console.error("Error loading user data:", error);
        }
      }
      authChecked = true;
      resolve();
    });
  });
};

router.beforeEach(async (to, from, next) => {
  console.log("--> redirecting from '" + from.path + "' to '" + to.path + "'");
  if (from.path.startsWith('/project')){
    store.commit("modProjects/setSelectedProject", null)
  }
  await checkAuthState();
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth) {
    const userData = store.getters["modLogin/userData"];
    if (userData) {
      next();
    } else {
      next("/");
    }
  } else {
    next();
  }
});

export default router;
