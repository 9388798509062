/* eslint-disable prettier/prettier */
import { getApp, initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import {
  //getFirestore,
  initializeFirestore, CACHE_SIZE_UNLIMITED
} from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAa4XHqd2kRa7JFmi7gJ3wm4GAb6SQR-GU",
  authDomain: "chirpjs-dev.firebaseapp.com",
  projectId: "chirpjs-dev",
  storageBucket: "chirpjs-dev.appspot.com",
  messagingSenderId: "10410922423",
  appId: "1:10410922423:web:d7399e705b08418f059832",
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = initializeFirestore(app, {
  cacheSizeBytes: CACHE_SIZE_UNLIMITED
});
const storage = getStorage();

export {
  auth,
  provider,
  signInWithPopup,
  app,
  db,
  storage,
};
