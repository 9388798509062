import {
  GoogleAuthProvider,
  User,
  onAuthStateChanged,
  signInWithPopup,
  signOut as firebaseSignOut,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { auth, db } from "../firebase-app";
import router from "@/router";
import { Module } from "vuex";
import store from ".";
import { doc, getDoc, onSnapshot, QuerySnapshot } from "firebase/firestore";
import { httpsCallable, getFunctions } from "firebase/functions";

const modLogin: Module<any, any> = {
  namespaced: true,
  state: {
    user: null as unknown as User,
    userData: null as any,
    auth: auth,
    provider: new GoogleAuthProvider(),
    authStateChange: null as any,
    getUserDataSnapshot: null as unknown as QuerySnapshot,
    showUserSetupLoading: false,
  },
  getters: {
    isAuthenticated(state): boolean {
      console.log("checking if user is authenticated", state.user);
      return state.user !== null;
    },
    currentUser(state): User | null {
      console.log("getting user: ", state.user);
      return state.user;
    },
    userData(state): User | null {
      console.log("getting user data: ", state.userData);
      return state.userData;
    },
  },
  mutations: {
    setShowUserSetupLoading(state, value) {
      state.showUserSetupLoading = value;
    },
    setUser(state, value) {
      state.user = value;
    },
    setAuth(state, auth) {
      state.user = auth;
    },
    setAuthStateChange(state, value) {
      state.authStateChange = value;
    },
    setUserDataSnapshot(state, value) {
      state.getUserDataSnapshot = value;
    },
    setUserData(state, data) {
      state.userData = data;
    },
  },
  actions: {
    async registerWithEmail({ commit, dispatch }, { email, password }) {
      commit("setShowUserSetupLoading", true);
      createUserWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
          console.log("calling setupUser");
          commit("setUser", userCredential.user);
          dispatch("setupUser", userCredential.user);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.error(errorCode, errorMessage);
          commit("setShowUserSetupLoading", false);
        });
    },
    async signInWithEmail(
      { commit, dispatch, state, getters },
      { email, password }
    ) {
      if (auth.currentUser) {
        state.getUserDataSnapshot();
        commit("setUser", null);
        commit("setUserData", null);
        dispatch("signOut");
      }
      try {
        if (!email || !password) {
          console.log("email and password are required");
          //commit("setLoginError", true);
          return;
        }

        await signInWithEmailAndPassword(auth, email, password).then(
          async (result) => {
            commit("setUser", result.user);
            await dispatch("getUserData", result.user.uid).then(() => {
              if (getters.userData.nameChanged) {
                console.log(
                  "routing to name page because the name has not changed"
                );
                router.push("/name");
              } else {
                router.push("/workspace");
              }
            });
          }
        );
      } catch (error) {
        console.log("Login Error: ", error);
      }
    },
    async signinWithGoogle({ state, commit, dispatch, getters }) {
      store.commit("setAppIsLoading", true);
      console.log("Signing in with google");
      const auth = state.auth;
      const provider = state.provider;
      if (auth && auth.currentUser) {
        console.log(`user logged in ${state.user}`);
        firebaseSignOut(auth);
      }
      console.log(auth);
      signInWithPopup(auth, provider)
        .then(async (result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential?.accessToken;
          // The signed-in user info.
          commit("setUser", result.user);
          const userDocRef = doc(db, `users/${result.user.uid}`);
          const userDocResult = await getDoc(userDocRef);
          if (userDocResult.exists()) {
            dispatch("getUserData");
            commit("setUserData", userDocResult.data());
            router.push("/workspace");
          } else {
            console.log("user not found, setting up user from router.");
            await store.dispatch("modLogin/setupUser", result.user);
          }

          store.commit("setAppIsLoading", false);
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          //const email = error.customData.email;
          // The AuthCredential type that was used.
          const credential = GoogleAuthProvider.credentialFromError(error);
          // ...
          console.log("error", error);
          store.commit("setAppIsLoading", false);
        });
    },
    async setupUser({ commit, dispatch }, user) {
      store.commit("setAppIsLoading", true);
      commit("setShowUserSetupLoading", true);
      const functions = getFunctions();
      const setupUser = httpsCallable(functions, "setupUser");
      console.log("before setupUser");
      await setupUser({
        uid: user.uid,
        email: user.email,
      }).then(async (result: any) => {
        console.log("inside setupUser");
        if (result.data.success) {
          dispatch("getUserData", user.uid);

          console.log("setupUser successful");
          commit("setShowUserSetupLoading", false);
        } else {
          console.log("setupUser failed!");
          console.log(result);
        }
        store.commit("setAppIsLoading", false);
        commit("setShowUserSetupLoading", false);
      });
    },
    async getUserData(
      { commit, dispatch, state },
      userUID
    ): Promise<object | null> {
      let userData = null;
      if (state.user === null) {
        return userData;
      }
      if (state.getUserDataSnapshot != null) {
        //close the snapShot before we open another one
        state.getUserDataSnapshot();
      }
      console.log("get user data called with user uid: " + userUID);
      const userDoc = doc(db, `users/${userUID}`);
      const userDataSnapShot = onSnapshot(userDoc, (doc) => {
        if (doc.exists()) {
          userData = doc.data();
          commit("setUserData", userData);
          console.log("setting up user data: ", userData);
          commit("setUserDataSnapshot", userDataSnapShot);
          if (userData.nameChanged == false) {
            router.push("/name");
          }
        } else {
          console.log("User document not found.");
        }
      });
      return userData;
    },

    signOut({ state, commit }) {
      console.log("Logging out...");
      router.push("/");
      commit("setUser", null);
      commit("setUserData", null);
      signOut(auth)
        .then(() => {
          console.log("Successfully logged out: ", state.user);
        })
        .catch((error: any) => {
          console.error("Error logging out:", error);
        });
    },
  },
  modules: {},
};
export default modLogin;
