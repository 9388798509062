<template>
  <div class="body">
    <div class="logo-medium"></div>
    <div class="big-header"><span class="brand-color">CHIRPJS</span></div>
    <div class="welcome-text">snippets & endless possibilities</div>
    <div class="vertical-spacer"></div>
    <div class="form-block w-form">
      <form
        class="form"
        @submit="
          (e) => {
            e.preventDefault();
            register();
          }
        "
      >
        <div class="login-form-inputs-wrapper">
          <label for="username" class="input-label">Registration E-Mail</label
          ><input
            v-model="email"
            class="input-text w-input"
            autofocus="true"
            maxlength="256"
            type="e-mail"
          />
          <br />
          <label for="username" class="input-label">Password</label
          ><input
            v-model="password"
            class="input-text w-input"
            autofocus="true"
            maxlength="256"
            type="password"
          />
          <br />
          <label for="username" class="input-label">Re-Type Password</label
          ><input
            v-model="rePassword"
            class="input-text w-input"
            autofocus="true"
            maxlength="256"
            type="password"
          />
        </div>
        <input class="btn w-button" type="submit" value="Register" />
        <div class="text-center">Or</div>
        <div class="btn google w-button" @click="$router.push('/')">Login</div>
      </form>
    </div>
    <div class="user-setup-div" :class="{ open: showUserSetupLoading }">
      <div class="logo-large with-text"></div>
      <div class="vertical-spacer"></div>
      <div class="welcome-text">snippets & endless possibilities</div>
      <div class="vertical-spacer"></div>
      <div class="setup-animation"></div>
      <div class="account-setup-text">Setting up your journey</div>
    </div>
    <Notification v-if="showNotification" :options="notificationOptions" />
  </div>
</template>

<script lang="ts">
import router from "@/router";
import { getFunctions, httpsCallable } from "firebase/functions";
import { defineComponent } from "vue";
import Notification from "@/components/Notification.vue";

import { mapActions, mapMutations, mapState } from "vuex";
export default defineComponent({
  name: "RegisterView",
  components: {
    Notification,
  },
  data() {
    return {
      email: "",
      password: "",
      rePassword: "",
      notificationOptions: null as any,
    };
  },
  setup() {
    //
  },
  async mounted() {
    //
  },
  computed: {
    ...mapState("modLogin", ["user", "userData", "showUserSetupLoading"]),
    ...mapState(["showNotification"]),
  },
  watch: {
    async userData(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        //");
      }
      console.log("newVal", newVal);
    },
  },
  methods: {
    ...mapActions("modLogin", ["registerWithEmail"]),
    ...mapMutations(["setShowNotification"]),
    ...mapMutations("modLogin", ["setShowUserSetupLoading"]),
    async register() {
      if (
        this.email.length > 0 &&
        this.password.length > 0 &&
        this.password == this.rePassword
      ) {
        await this.registerWithEmail({
          email: this.email,
          password: this.password,
        });
      } else {
        this.notificationOptions = {
          isError: true,
          question: false,
          yesText: "ok",
          noText: "",
          title: "Regesteration Error!",
          text: "Check the password.",
          yesFunction: null,
          noFunction: null,
        };
        this.setShowNotification(true);
        this.showUserSetupLoading = false;
      }
    },
  },
});
</script>
