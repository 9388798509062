import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.chirp)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["btn chirp-search", { selected: _ctx.selectedChirp && _ctx.selectedChirp.id == _ctx.chirp.id }]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => {
      if (!_ctx.selectedChirp || (_ctx.selectedChirp && _ctx.selectedChirp.id != _ctx.chirp.id)) {
        _ctx.setSelectedChirp(_ctx.chirp);
      } else {
        _ctx.setSelectedChirp(null);
      }
    })
      }, _toDisplayString(_ctx.chirp.name), 3))
    : _createCommentVNode("", true)
}