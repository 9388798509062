import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "workspace-item-wrapper chirp" }
const _hoisted_2 = { class: "item-details title" }
const _hoisted_3 = { class: "item-details light" }
const _hoisted_4 = { class: "item-details light bold" }
const _hoisted_5 = {
  key: 0,
  class: "item-options"
}
const _hoisted_6 = {
  key: 1,
  class: "item-options"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[8] || (_cache[8] = _createElementVNode("div", { class: "item-icon active" }, "cheer", -1)),
    _createElementVNode("div", {
      class: "item-detail-wrapper",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToChirpPage(_ctx.chirp.id)))
    }, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.chirp.name), 1),
      _createElementVNode("div", _hoisted_3, "By " + _toDisplayString(_ctx.chirp.author), 1),
      _createElementVNode("div", _hoisted_4, " Updated: " + _toDisplayString(new Date(_ctx.chirp.updatedDate).toLocaleString()), 1)
    ]),
    _createElementVNode("div", {
      class: "item-icon",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showSettings = true))
    }, "settings"),
    (_ctx.showSettings)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "item-option" }, "Rename", -1)),
          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "item-option" }, "Unpublish", -1)),
          _createElementVNode("div", {
            class: "item-option red",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.deleteQuestion = true))
          }, "Delete"),
          _createElementVNode("div", {
            class: "item-option-close-btn",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showSettings = false))
          }, " close ")
        ]))
      : _createCommentVNode("", true),
    (_ctx.showSettings && _ctx.deleteQuestion)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", {
            class: "item-option",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.deleteQuestion = false))
          }, "Cancel"),
          _createElementVNode("div", {
            class: "item-option red",
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.deleteChirp(_ctx.chirp.id)))
          }, "Delete")
        ]))
      : _createCommentVNode("", true)
  ]))
}